.newsletter-section {
  padding: 30px 0;

  .c-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    > div {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .text {
    padding-right: 20px;
    p {
      margin: 0;
      font-size: 1rem;
      text-transform: uppercase;
    }
  }

  .form {
    width: 520px;
    max-width: 100%;
    position: relative;
    form {
      display: flex;
      button.submit {
        flex: 0 0 146px;
        font-size: 15px;
        color: $white-c !important;
      }
    }
    .notification{
      position: absolute;
      bottom: -15px;
      right: 0;
    }
  }
  @include media-breakpoint-down(md) {
    .text {
      padding-right: 0;
    }
  }
  @include media-breakpoint-down(xs){
    padding: 20px 0;
    .text{
      padding: 0 0 15px 0 !important;
    }
    .form {
      width: 100%;
      form {
        display: flex;
        flex-direction: column;
        button.submit {
          flex: unset;
          margin: 0;
          position: absolute;
          right: 0;
          top: 5px;
        }
      }
    }
  }
}