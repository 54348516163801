.title-parallax {
  min-height: 150px;
  // @extend .parallax-effect;
  text-align: center;
  position: relative;

  // background-size: cover;
  // background-position: center;
  // background-attachment: fixed;

  // .bg-overlay {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  //   width: 100%;
  //   content: "";
  //   height: 100%;
  // }

  .text {
    width: 100%;
    position: absolute;
    @include center(xy);

    @media (max-width: 575.98px) {
      top: 60% !important;
    }
  }

  h1{
    font-size: 36px;
    font-weight: 700;
    color: $white-c;
    line-height: 44px;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px $black-c;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
  .title-paral {
    font-size: 36px;
    font-weight: 700;
    color: $white-c;
    line-height: 44px;
    text-transform: uppercase;
    text-shadow: 1px 1px 3px $black-c;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
  @include media-breakpoint-down(xs) {
    min-height: 100px;
  }
}

.page-title-holder {
  position: relative;
  top: 20px;

  h1 {
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    @include media-breakpoint-down(lg) {
      font-size: 1.5rem;
    }
  }
}

//////////////////////////////////////////////////////////
//SIMPLE PAGE
//////////////////////////////////////////////////////////
.simple-page {
  /// //////////////////////////////////////////////////////////
  //BRANDS
  //////////////////////////////////////////////////////////
  .container-brands {
    display: flex;
    flex-direction: column;
    gap: 80px;
    justify-content: center;
    width: 100%;
    align-items: center;

    .wrapper-text_top {
      text-align: center;

      @media (min-width: 992px) {
        width: 851px;
      }

      & span {
        color: #707070;
        font-size: 18px;
        line-height: 27px;
        font-weight: 300;
      }
    }

    .brands-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      row-gap: 22px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .list-img img {
        border: 1px solid transparent;
        transition: border 0.8s ease-in-out;

        &:hover {
          border: 1px solid $color1-c;
        }
      }
    }
  }

  .social-links {
    display: block;
    @extend .clearfix;
    margin-top: 20px;

    li {
      display: inline-block;
      float: left;

      &:not(:first-of-type) {
        a {
          border-left: 0;
        }
      }
    }

    a {
      display: block;
      line-height: 48px;
      width: 50px;
      border: 1px solid $border-c;
      color: #888;
      text-align: center;
      font-size: 1.25rem;

      &:hover {
        border-color: $color1-c;
        background-color: $color1-c;
        color: $white-c;
      }
    }
  }

  .brand-search-block {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $border-c;
    .flex-block {
      margin-left: 20px;
    }
    input {
      min-width: 350px;
    }
  }
  @include media-breakpoint-down(sm) {
    .brand-search-block {
      flex-direction: column;
      align-items: flex-start;
      .flex-block {
        width: 100%;
        margin-top: 20px;
        margin-left: 0px;
      }
      input {
        min-width: unset;
        width: 100%;
      }
    }
  }

  /// //////////////////////////////////////////////////////////
  //OUR CUSTOMERS
  //////////////////////////////////////////////////////////
  .container-our_customers {
    display: flex;
    width: 100%;
    flex-direction: column;

    .wrapper-item {
      display: flex;
      flex-direction: row-reverse;
      gap: 40px;
      padding: 80px 0px;
      border-bottom: 1px solid $gray-c;

      @media (max-width: 1199.98px) {
        flex-direction: column-reverse;
      }

      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        border: none;
        padding-bottom: 0px;
      }

      @media (min-width: 1200px) {
        &:nth-child(2n) {
          flex-direction: row;
        }
      }

      .img-item {
        @media (min-width: 1200px) {
          height: 400px;
        }
      }

      .wrapper-info {
        h2 {
          color: $black-c;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
        }

        .add_text {
          color: $red-c;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
        }
        .description {
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #707070;
        }

        .btn-wrapper {
          display: flex;
          a {
            text-transform: math-auto;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;

            .arrow-right-red {
              display: none;
            }

            &:hover {
              .arrow-right-white {
                display: none;
              }
              .arrow-right-red {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  ///////////////////////////
  ///   OUR SERVICIES
  ///////////////////////////
  .container_our_services {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;

    .wrapper-item {
      display: flex;
      flex-direction: row-reverse;
      gap: 40px;
      padding: 80px 0px;
      border-bottom: 1px solid $gray-c;

      @media (max-width: 1199.98px) {
        flex-direction: column;
      }

      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        border: none;
        padding-bottom: 0px;
      }

      @media (min-width: 1200px) {
        &:nth-child(2n) {
          flex-direction: row;
        }
      }

      .img-item {
        @media (max-width: 1199.98px) {
          // width: 400px;
          height: 200px;
        }
        @media (min-width: 1200px) {
          height: 400px;
        }
      }

      .wrapper-info {
        h2 {
          color: $black-c;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
        }

        .add_text {
          color: $red-c;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
        }
        .description {
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #707070;
        }

        .btn-wrapper {
          display: flex;
          a {
            text-transform: math-auto;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 10px;

            .arrow-right-red {
              display: none;
            }

            &:hover {
              .arrow-right-white {
                display: none;
              }
              .arrow-right-red {
                display: block;
              }
            }
          }
        }
      }
    }

    .wrapper-item:nth-child(1) .wrapper-info h2::before {
      content: "";
      width: 47px;
      height: 33px;
      background-repeat: no-repeat;
      background-image: url("../images/icon8-red.svg");
      background-size: contain;
      display: block;
      margin-bottom: 10px;
    }
    .wrapper-item:nth-child(2) .wrapper-info h2::before {
      content: "";
      width: 47px;
      height: 33px;
      background-repeat: no-repeat;
      background-image: url("../images/icon9-red.svg");
      background-size: contain;
      display: block;
      margin-bottom: 10px;
    }
    .wrapper-item:nth-child(3) .wrapper-info h2::before {
      content: "";
      width: 47px;
      height: 33px;
      background-repeat: no-repeat;
      background-image: url("../images/icon10-red.svg");
      background-size: contain;
      display: block;
      margin-bottom: 10px;
    }
  }

  ///////////////////////////
  ///  CAREER
  ///////////////////////////
  .career-section {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .wrapper-career {
      width: 100%;
      display: flex;
      gap: 20px;
      align-items: center;

      @media (min-width: 768px) {
        border: 1px solid $gray-c;
      }

      @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: baseline;
        border-bottom: 1px solid $gray-c;
        padding-bottom: 20px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0px;
        }
      }

      .career-img {
        @media (min-width: 768px) {
          border-right: 1px solid $gray-c;
        }
      }

      .wrapper-info {
        .title {
          color: $black-c;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
        }
        .desc > p {
          color: #707070;
          font-weight: 400;
          line-height: 27px;

          @media (max-width: 767.98px) {
            font-size: 15px;
            max-width: 350px;
          }
        }
        .desc{
          a{
            color: #ba1b2e;
          }
        }
        .btn_career {
          > a {
            font-weight: 500;
            line-height: 22px;
            font-size: 18px;
          }
        }
      }
    }
  }
  .wrapper_form_career {
    @media (min-width: 768px) {
      display: flex;
      justify-content: center;
    }

    form {
      & input::placeholder {
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        color: $gray-c !important;
      }

      & input,
      textarea {
        border: 1px solid $gray-c;
      }
      #career {
        .selected-placeholder {
          font-weight: 300;
          font-size: 18px;
          line-height: 22px;
          color: $gray-c;
        }
      }
     

      .c-input,
      .file-upload {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .c-textarea {
        margin-top: 0;
      }

      .file-upload-wrapper {
        $height: 36px;
        border: 1px solid $gray-c;
        padding: 20px 11px;
        width: 100%;
        position: relative;

        &::after {
          content: attr(data-text);
          font-size: 18px;
          font-weight: 300 !important;
          position: absolute;
          top: 0;
          left: 0;
          color: $gray-c;
          padding: 10px 15px;
          display: block;
          width: calc(100% - 40px);
          pointer-events: none;
          z-index: 1;
          height: $height - 20px;
          line-height: $height - 20px;
        }

        &::before {
          content: "\f093  Upload PDF";
          position: absolute;
          top: 2px;
          right: 10px;
          display: inline-block;
          height: 36px;
          background: $red-c;
          color: #fff;
          font-weight: 700;
          z-index: 1;
          font-size: 12px;
          line-height: $height;
          padding: 0 15px;
          pointer-events: none;
          border-radius: 3px;
          font-family: "Open Sans", "FontAwesome", sans-serif;
        }
        input {
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 99;
          height: 100%;
          margin: 0;
          padding: 0;
          display: block;
          cursor: pointer;
          width: 100%;
        }
      }

      .container-btn-captcha {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;
        align-items: center;
        padding-top: 40px;

        @media (max-width: 575.98px) {
          justify-content: center;
          flex-direction: column;
          row-gap: 10px;
          padding-top: 20px;
        }

        .wrapper-captcha {
          display: flex;
        }
      }
    }
    .container-btn-captcha {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      .wrapper-captcha {
        display: flex;
      }
    }
  }

  .title-simple_page {
    font-weight: 700;
    color: $black-c;
    font-size: 24px;
    line-height: 29px;
  }
  .wrapper_text-editor {
    span {
      color: $red-c;
      line-height: 27px;
      font-size: 18px;
      font-weight: 300;
    }
    p {
      color: #707070;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
    }
  }
}

/// //////////////////////////////////////////////////////////
//ABOUT US
//////////////////////////////////////////////////////////
.about-page {
  position: relative;

  .text-editor {
    div {
      display: flex;
      gap: 40px;
      width: 100%;

      @media (max-width: 991.98px) {
        flex-direction: column;
      }
    }
    // flex-wrap: wrap;

    // &:last-child {
    //   background-color: gold !important;
    // }

    @media (max-width: 1199.98px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p {
      max-width: 563px;
      color: #707070;
      line-height: 27px;
      font-weight: 300;
      font-size: 18px;
    }

    .bg-red {
      background-color: $red-c;
      text-align: center;
      padding: 20px 0px;
      flex-wrap: wrap;
      justify-content: center;
      flex-basis: 100%;
      margin: 100px 0px;
      p{
        color: $white-c;
        font-size: 2.25rem;
        max-width: unset;
        line-height: 1.1;
      }
    }

    // @media (max-width: 575.98px) {
    //   & p {
    //     width: 300px;
    //     font-size: 15px;
    //   }
    // }

    // & img {
    //   line-height: 0px;
    //   font-weight: 0;
    // }
  }

  .container-certificate {
    width: 100%;

    .wrapper-certificate {
      padding: 40px 20px;
      border: 1px solid $gray-c;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      .cert-flex {
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
        align-items: center;
        flex: 1;

        .cert-title {
          p {
            min-height: 30px;
            font-weight: 700;
            color: $red-c;
            line-height: 36px;
            font-size: 24px;
            margin-bottom: 0;
          }
        }
        .cert-desc {
          span {
            color: #707070;
            font-weight: 400;
            line-height: 27px;
            font-size: 18px;
          }
        }
      }
    }

    .slick-list {
      display: flex;
      > .slick-track {
        display: flex;
        justify-content: center;
        gap: 10px;
        flex: 1;
      }
    }

    .c-slider-arr {
      top: 50%;
      color: $color2-c;
      border-color: $color2-c;
      line-height: 20px;
      width: 30px;
      font-size: 50px;
      &.prev-slide {
        left: -42px;
      }
      &.next-slide {
        right: -42px;
      }
      &:hover {
        color: $color1-c !important;
      }
    }
    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
    @include media-breakpoint-down(xs) {
      .c-slider-arr {
        &.prev-slide {
          left: 0;
          transform: translate(-50%, -50%);
        }
        &.next-slide {
          right: 0;
          transform: translate(50%, -50%);
        }
      }
    }
  }

  .section-bg_img {
    background-color: $red-c;
    width: 100%;
    margin: 100px 0px;
  }

  .container-our_team {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;

    @media (max-width: 991.98px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 575.98px) {
      grid-template-columns: 1fr;
    }

    .wrapper-our_team {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 12px;

      .img-our_team {
        & img {
          border-radius: 50%;
        }
      }

      .desc-our_team {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        flex: 1;

        .title-our_team {
          color: $red-c;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
        }

        .add-our_team {
          color: $black-c;
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          min-height: 40px;
        }
        .bottom_text-our_team {
          color: #707070;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
}

//////////////////////////////////////////////////////////
//CONTACT PAGE
//////////////////////////////////////////////////////////
.contact-page {
  & input::placeholder,
  textarea::placeholder {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: $gray-c;
  }
  & input,
  textarea {
    border: 1px solid $gray-c;
  }

  & form {
    .c-input {
      margin-top: 0;
      margin-bottom: 10px;
    }
    .c-textarea {
      min-height: 175px;
      margin-top: 0;
    }

    .container-btn-captcha {
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-direction: row;
      align-items: center;

      @media (max-width: 575.98px) {
        justify-content: center;
        flex-direction: column;
        row-gap: 10px;
        margin-bottom: 20px;
      }

      .wrapper-captcha {
        transform: translateY(15px);
        .recaptcha-box {
          padding-top: 0px;
        }
      }
    }
  }
  .contact-data {
    background-color: $black-c;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px 40px 20px;
    min-height: 392px;

    h2 {
      font-size: 1.25rem;
      text-transform: uppercase;
      // margin-bottom: 0;
      color: $white-c;
    }

    table {
      border: 0;
      // font-size: 1.125rem;
      // margin-top: 20px;
      color: $white-c;

      tbody {
        // border-bottom: 1px solid $border-c;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      tr {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: row;
      }

      td {
        // border: 0;
        // border-top: 1px solid $border-c;
        // line-height: 26px;
        // padding: 32px 0 32px 80px;
        position: relative;
        padding-inline: 30px;
        display: flex;
        align-items: center;
        font-size: 18px !important;

        > p {
          margin: 0px;
          padding: 0px;
          font-weight: 300;
          line-height: 29px;
          font-size: 18px;

          @media (max-width: 575.98px) {
            font-size: 12px;
          }
        }

        &:before {
          content: "";
          font-family: "FontAwesome";
          position: absolute;
          // left: 25px;
          left: 0;
          @include center(y);
          font-size: 14px;
          // width: 30px;
          display: inline-block;
          text-align: center;
          font-weight: 900;
          line-height: 22px;
        }
      }

      tr:nth-of-type(1) td:before {
        content: "\f041";
      }
      tr:nth-of-type(2) td:before {
        content: "\f095";
      }
      tr:nth-of-type(3) td:before {
        content: "\f0e0";
      }
      tr:nth-of-type(4) td:before {
        content: "\f017";
      }
      tr:nth-of-type(5) td:before {
        content: "\f19c";
      }
    }
  }

  // .second-block {
  //   .block-title {
  //     font-size: 1.25rem;
  //     text-transform: uppercase;
  //     // margin-bottom: 0;
  //     color: goldc;
  //     // padding-bottom: 10px;
  //   }
  // }

  .loader-holder {
    margin-top: -10px;
    padding: 0;
    span {
      font-size: 1rem;
      color: $red-c;
      margin-right: 15px;
      font-weight: 700;
    }
  }

  // @include media-breakpoint-down(md) {
  //   .second-block {
  //     margin-top: 40px;
  //   }
  // }

  // @include media-breakpoint-only(xs) {
  //   .contact-data {
  //     table {
  //       td {
  //         padding: 15px 0 15px 40px;

  //         &:before {
  //           left: 5px;
  //         }
  //       }
  //     }
  //   }
  // }
}

//////////////////////////////////////////////////////////
//FAQ PAGE
//////////////////////////////////////////////////////////
.faq-page {
  .faq-accordion {
    clear: both;

    .ui-accordion-header-icon {
      display: none;
    }
  }

  .red-container {
    background: #BA1B2E;
    color: $white-c;
    padding: 40px 0;
    text-align: center;
    .red-t{
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
}

.faq-accordion {
  .accordion-block {
    border-bottom: 1px solid $border-c;
    > .faq-trigger {
      margin: 0;
      font-size: 24px;
      font-weight: 400;
      color: $black-c;
      padding: 12px 45px 20px 0;
      position: relative;
      line-height: 36px;
      cursor: pointer;
      display: flex;
      align-items: center;

      > span {
        position: absolute;
        right: 0;
        top: 10px;
        height: 42px;
        width: 42px;

        // padding: 1px 16px;
        display: inline-block;
        text-align: center;
        border: 1px solid transparent;
        background-color: $red-c;
        color: $white-c;
        @include transition-all(0.6s);
        &::before {
          content: "+";
          font-size: 36px;
        }
      }
    }

    &.active > .faq-trigger {
      color: $red-c;

      > span {
        border: 1px solid $red-c;
        background-color: transparent;
        color: $red-c;
        font-weight: 700;
        @include transform(rotate(180deg));
        &::before {
          content: "-";
          font-size: 37px;
        }
      }
    }

    > div {
      padding: 15px 0;
      color: #707070;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }
}

//////////////////////////////////////////////////////////
// To top
//////////////////////////////////////////////////////////
#to-top {
  display: inline-block;
  background-color: $color1-c;
  width: 40px;
  height: 40px;
  text-align: center;
  position: fixed;
  bottom: 40px;
  right: 50px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  &:after {
    content: "\f106";
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    font-size: 1.875rem;
    line-height: 35px;
    color: $white-c; // $black-c
  }
  &:hover {
    cursor: pointer;
    background-color: $gray-c;
    // add changed color on :hover after selector
    &:after {
      color: $black-c;
    }
  }
  &:active {
    background-color: $gray-c;
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
  @include media-breakpoint-down(xs) {
    right: 20px;
  }
}
.f-column-reverse {
  //margin-top: 30px;
  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0px;
  }
}
.send-request-page{
  .product-info {
    max-width: 710px;
    margin: 0 auto;
    text-align: center;
  }
  .add-more-counters{
    margin-top: 20px;
    width: 100%;
    @include transition-all(0.4s);
  }
  .p-description{
    font-size: 1rem;
    margin-bottom: 0;
    color: #707070; 
  }
  .company-data-holder {
    padding-bottom: 40px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  .radio-box{
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
  .wrapper-select-person,
  .first-block {
    max-width: 460px;
    margin: 0 auto;
  }
  .wrapper-select-person{
    padding-top: 30px;
  }
  .first-block{
    .c-textarea,
    .c-input{
      &::placeholder {
        font-size: 1.125rem;
        font-weight: 300;
        color: #999 !important; 
      }
      
      &::-ms-input-placeholder { /* Edge 12-18 */
        font-size: 1.125rem;
        font-weight: 300;
        color: #999 !important; 
      }
    }
    .btn-block {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
.banking-page{
  .banking-page-block{
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px 70px;
    padding-bottom: 60px;
    .banking-item{
      border: 1px solid #DDD;
      padding: 30px 25px;
      .banking-info {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 15px;
        .banikng-title{
          color: #1B1918;
          font-size: 1.25rem;
        }
      }
    }
  }
  .section-title-b2{
    font-size: 1.5rem;
    font-weight: 700;
    color: #1B1918;
    margin-bottom: 20px;
  }
  .section-text-b2{
    font-size: 1.125rem;
    margin-bottom: 30px;
  }
  .equipment-block{
    .equipment-item {
      display: flex;
      align-items: center;
      .equipment-info {
        width: 40%;
        text-align: center;
      }
      .text-block {
        width: 60%;
        .equipment-title{
          color: #BA1B2E;
          font-size: 1.25rem;
          font-weight: 700;
          margin-bottom: 20px;
        }
        p{
          font-size: 1.125rem;
        }
      }
      @include media-breakpoint-up(sm){
        &:nth-child(even) {
          .equipment-info {
            order: 2;
          }
          .text-block{
            order: 1;
          }
        }
      }
    }
  }
  .bg-gray{
    background: #DDD;
    padding: 50px 0;
    margin-bottom: 20px;
    .d-text{
      color: #707070;
      font-weight: 600;
      font-size: 1.5rem;
      margin-bottom: 0;
      span{
        color: #BA1B2E;
      }
    }

  }
  .title-sl{
    font-size: 1.5rem;
    font-weight: 700;
    color: #1B1918;
    margin-bottom: 20px;
    span{
      color: #BA1B2E;
    }
  }
  .bg-red{
    background: #BA1B2E;
    color: $white-c;
    padding: 40px 0;
    text-align: center;
    .red-t{
      font-size: 2.25rem;
      font-weight: 700;
    }
  }
  .equipment-item {
    display: flex;
    justify-content: center;
  }
  .client-block{
    .client-grid{
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      //gap: 45px;
      align-items: center;
    }
    .ministries-grid{
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 45px;
      align-items: center;
    }
  }
  @include media-breakpoint-down(md){
    .banking-page-block{
      gap: 20px;
      padding-bottom: 20px;
    }
    .client-block{
      .client-grid {
        grid-template-columns: repeat(4,minmax(0,1fr));
        //gap: 20px;
      }
    }
  }
  @include media-breakpoint-down(sm){
    .equipment-block{
      .equipment-item{
        flex-direction: column;
        margin-bottom: 10px;
        .equipment-info,
        .text-block{
          width: 100%;
        }
        .equipment-info{
          margin-bottom: 10px;
        }
      }
    }
    .bg-gray{
      padding: 20px 0;
    }
    .client-block{
      .client-grid {
        grid-template-columns: repeat(3,minmax(0,1fr));
        //gap: 20px;
      }
    }
  }
  @include media-breakpoint-down(xs){
    .client-block{
      .client-grid {
        grid-template-columns: repeat(2,minmax(0,1fr));
        //gap: 20px;
      }
      .ministries-grid{
        grid-template-columns: repeat(2,minmax(0,1fr));
        gap: 0px;
      }
    }

    .banking-page-block{
      grid-template-columns: repeat(1,minmax(0,1fr));
    }
  }
}

