.gc-display-area {
  // border: 0;
  padding: 0;
  border: 1px solid #707070;
}

.gc-zoom {
  padding: 0;
}

.gc-zoom-inner {
  left: 0 !important;
  top: 0 !important;
}

.glass-case ul li {
  border: 0;
  // background-color: $white-c;
  border: 1px solid $gray-c;
  padding: 14px 19px;
}
.glass-case ul li.gc-active,
.glass-case ul li.gc-active:hover {
  // background-color: $color1-c;
  border: 1px solid #707070;

  cursor: default;
}

.gc-icon {
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.gc-display-area .gc-icon-next,
.gc-display-area .gc-icon-prev {
  font-size: 36px !important;
  z-index: 2;
}
.gc-thumbs-area-next .gc-icon,
.gc-thumbs-area-prev .gc-icon {
  color: #000;
}
.gc-thumbs-area.gc-hz .gc-thumbs-area-prev,
.gc-thumbs-area.gc-hz .gc-thumbs-area-next{
  background-color: rgba(134, 134, 134, 0.32);
}
.gc-thumbs-area-prev .gc-icon-prev{
  font-size: 2rem !important;
  font-weight: 700;
}
.gc-thumbs-area-next .gc-icon-next {
  font-size: 2rem !important;
  font-weight: 700;
}
.gc-icon-prev::before {
  left: -4px;
}
.gc-icon-next::before {
  right: -4px;
}
@include media-breakpoint-down(sm) {
  .gc-display-area {
    pointer-events: none;
  }
}
