.albums-list {
  padding: 80px 0 70px 0;

  @include media-breakpoint-down(lg) {
    padding: 40px 0 30px 0;
  }
}

.gallery-item {
  position: relative;
  margin-bottom: 30px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0px; left: 0;
    width: 100%; height: 1px;
    background-color: $gray-c;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0; left: 50%;
    @include translate(-50%, 0);
    width: 0; height: 1px;
    background-color: $black-c;
    @include transition-all(0.4s);
    z-index: 1;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }

  .image-block {
    position: relative;
    overflow: hidden;
  }

  .text-block {
    padding: 0 5px;
    text-align: center;

    h3 {
      height: 3rem;
      margin: 0;

      position: relative;
      a {
        display: inline-block;
        width: 100%;
        position: absolute;
        font-size: 0.75rem;
        font-weight: 700;
        @include center(xy);
      }
    }
  }
}

.album-about {
  padding: 80px 0 50px 0;

  @include media-breakpoint-down(lg) {
    padding: 40px 0 30px 0;
  }

  .cover-image-holder {
    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 0;
  }

  ul{
    padding-top:5px;
    padding-left: 10px;

    li{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top: 8px; left: -10px;
        width: 4px; height: 4px;
        background-color: $color1-c;
      }
    }
  }

  a{
    text-decoration: underline;
    color: $text-c;
    &:hover{
      text-decoration: underline;
      color: $color1-c;
    }
  }

  a.pdf {
    display: inline-block;
    line-height: 20px;
    padding: 5px 0 5px 30px;
    text-decoration: none;
    background: url('../images/pdf.png') no-repeat center left;
    &:hover {
      color: $color1-c;
      text-decoration: none;
    }
  }
}

.album-images {
  padding-bottom: 50px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 30px;
  }
}