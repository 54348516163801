.navigation {
  // border-top: 1px solid $gray-c;
  // border-bottom: 1px solid $gray-c;
  padding: 20px 0px;

  nav > ul {
    margin: 0;

    li {
      list-style: none;
    }

    a {
      display: block;
      width: 100%;

      img {
        max-width: 30px;
        margin-right: 10px;
      }
    }

    > li {
      display: inline-block;
      float: left;
      margin-right: 10px;
      font-size: 0.875rem;
      position: relative;

      @include media-breakpoint-only(lg) {
        margin-right: 5px;
      }

      > span:after {
        position: absolute;
        right: 0;
        content: "\f078";
        font-size: 18px;
        font-family: "FontAwesome";
        color: $black-c;
        font-weight: 900;
        top: 50%;
        // transform: translateY(-50%);
        transform: translate(4px, -50%);
        @include transition-all(0.4s);
      }

      > a {
        padding: 10px 17px 10px 10px;
        line-height: 22px;
        color: $text-c;
        font-weight: 500;
        font-size: 1.125rem;

        > span {
          color: $color1-c;
        }
      }

      > ul {
        position: absolute;
        left: 0;
        top: 110%;
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        @include transition(opacity 0.4s ease-in-out);
      }

      > ul {
        a {
          font-weight: 700;
        }
      }
      &:hover {
        &::before {
          content: "";
          position: absolute;
          bottom: 5px;
          right: -50%;
          transform: translateX(-40%);
          width: 100%;
          height: 4px;
          background-color: $red-c;
        }
        > ul {
          top: 100%;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          background-color: $white-c;
          padding: 20px 0;
          border: 1px solid $border-c;
          display: flex;
          gap: 18px;
          align-items: baseline;
          flex-direction: column;
          width: 300px;
          // min-height: 442px;
          height: auto;

          @media (min-width: 992px) and (max-width: 1199.98px) {
            width: 280px;
          }

          .submenu-trigger::after {
            right: 0;
            content: "\f054";
            font-size: 18px;
            font-family: FontAwesome;
            color: $black-c;
            font-weight: 900;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.4s ease-in-out;
            cursor: pointer;
          }

          a {
            color: $black-c;
            line-height: 22px;
            font-size: 1.125rem;
            white-space: initial;
            &:hover {
              color: $color1-c;
            }
          }
          > li {
            &:hover {
              & a {
                color: $red-c !important;
              }
              .submenu-trigger::after {
                color: $red-c;
              }
            }
          }
        }
      }
      &.faq-l{
        margin: 0;
        // a{
        //   padding: 10px;
        // }
        &:hover{
          &::before {
            content: "";
            right: 0;
            transform: unset;
            width: calc(100% - 10px);
          }
        }
      }
    }

    > li li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 18px;
      padding: 0 10px;
      // align-items: baseline;

      // pod-menu
      ul {
        background-color: $white-c;
        border: 1px solid $gray-c;
        // border-left: none;
        position: absolute;
        left: 100%;
        // top: -1px;
        // transform: translate(0, -7px);
        opacity: 0;
        visibility: hidden;
        z-index: -9;
        margin: 0;
        @include transition(opacity 1s, visibility 1s);
        padding: 20px 0;

        width: 600px;
        // height: 442px;
        height: auto;

        -webkit-column-count: 2;
        -o-column-count: 2;
        // column-fill: auto;
        // -moz-column-fill: auto;
        // -webkit-column-fill: auto;
        // -o-column-fill: auto;

        @media (min-width: 992px) and (max-width: 1199.98px) {
          width: 420px;
        }

        > li {
          margin-bottom: 18px;
          a {
            font-weight: 400;
            line-height: 22px;
          }
        }
      }

      &:hover {
        ul {
          margin-right: 0;
          opacity: 1;
          visibility: visible;

          > li {
            & a {
              &:only-child {
                color: $black-c !important;
                transition: color 0.4s ease-in-out;
              }
              &:hover {
                color: $red-c !important;
              }
            }
          }
        }
      }
    }
  }
}

#responsive-menu-button {
  display: none;
  width: 30px;
  height: 30px;
  position: relative;
  @include transform(rotate(0deg));
  @include transition-all(0.25s);
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #282829;
    font-weight: 900;
    // border-radius: 9px;
    opacity: 1;
    right: 0;
    @include transform(rotate(0deg));
    @include transition-all(0.25s);
  }

  span:nth-child(1) {
    top: 3px;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 13px;
  }

  span:nth-child(4) {
    top: 23px;
  }
}

.sidr-animating,
.sidr-open {
  #responsive-menu-button {
    span:nth-child(1) {
      left: 50%;
      top: 13px;
      width: 0%;
    }

    span:nth-child(2) {
      top: 13px;
      @include transform(rotate(45deg));
    }

    span:nth-child(3) {
      top: 13px;
      @include transform(rotate(-45deg));
    }

    span:nth-child(4) {
      left: 50%;
      top: 13px;
      width: 0%;
    }
  }
}

#navigation {
  display: inline-block;
}
@include media-breakpoint-down(md) {
  #responsive-menu-button {
    display: block;
  }
  #navigation {
    display: none;
  }
}
