.three-links-section {
  border-top: 1px solid $border-c;
  border-bottom: 1px solid $border-c;

  .c-flex {
    display: flex;
    // border-left: 1px solid $border-c;

    > div {
      flex: 1;
      // border-right: 1px solid $border-c;
      position: relative;
      // text-align: center;
      // padding: 50px 20px;
      padding: 27px 0px;

      &:not(:last-of-type) {
        border-right: 1px solid $border-c;
      }

      a {
        // text-align: left;
        // display: inline-block;
        line-height: 20px;
        // position: relative;
        // top: 0;
        // padding-left: 40px;
        font-weight: 700;
        text-transform: uppercase;
        color: #707070;
        transition: color 0.6s ease-in-out;

        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        gap: 10px;
        .fa {
          font-size: 32px;
          line-height: 40px;
          // position: absolute;
          // top: 50%;
          // left: 0;
          color: #707070;
          transition: color 0.6s ease-in-out;
          // @include translate(0, -50%);
        }
        &:hover {
          color: $color1-c;

          .fa {
            color: $color1-c;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    border: 0;
    padding: 15px 0 0 0;

    .c-flex {
      border-top: 1px solid $border-c;
    }
  }

  @include media-breakpoint-between(md, lg) {
    .c-flex {
      > div {
        padding: 30px 10px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .c-flex {
      > div {
        padding: 20px 10px;
        a {
          .fa {
            font-size: 26px;
          }
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    .c-flex {
      display: block;
      > div {
        border-bottom: 1px solid $border-c;
        text-align: left;
        a {
          top: 0;
          @include translate(0, 0);
        }
      }
    }
  }
}
