#header {
  position: relative;
  z-index: 2;

  section.top {
    background-color: $color2-c;
    color: $white-c;
    position: relative;
    padding: 14px 0px;

    .wrapper-header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .contact {
      font-size: 16px;
      .email {
        display: flex;
        gap: 20px;
        align-items: baseline;

        span.text-banner-email {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
        .email-wrapper-banner {
          display: flex;
          gap: 8px;
          align-items: baseline;
        }
      }
      > div {
        display: inline-block;
        &:first-of-type {
          margin-right: 30px;
        }
      }
      .icon {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        vertical-align: middle;
        text-align: center;
        line-height: 28px;
        border: 1px solid $white-c;
      }
      .darker {
        color: $white-c;
        font-weight: 400;
        a{
          color: $white-c;
        }
      }
      .icon-phone {
        display: none;
      }
      @include media-breakpoint-only(sm) {
        > div {
          display: block;
          line-height: 14px;

          .icon {
            display: none;
          }
        }
      }

      @include media-breakpoint-only(xs) {
        display: none;
      }
    }

    .options {
      display: flex;
      gap: 20px;
      > div {
        display: flex;
        gap: 20px;
        .c-drop-trigger {
          display: block;
          line-height: 22px;
          font-weight: 400;
          color: $white-c;
        }

        .c-drop-trigger-mobile {
          display: none;
        }
      }
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  section.main {
    .c-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .flex-wrapper-nav {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      gap: 25px;
      .left-wrapper {
        display: flex;
        gap: 25px;
        align-items: center;
        & a {
          > p {
            margin-bottom: 0 !important;
          }
        }
        .logo{
          width: 140px;
        }
      }
      .right-wrapper {
        display: flex;
        gap: 20px;
        .btn-t1.red.btn-contact-header{
          padding: 12px 10px;
          a{
            color: $white-c;
          }
          &:hover{
            a{
              color: $red-c;
            }
          }
        }
        @include media-breakpoint-down(md) {
          order: 1;
        }
      }
    }

    .search {
      //width: 320px;
      //display: inline-block;
      .search-preview{
        .fa-times{
          display: none;
        }
      }

      &.drop-open{
        .search-preview{
          .fa-times{
            display: block;
          }
          .fa-search{
            display: none;
          }
        }

      }
      form {
        display: flex;
      }
      .btn-t1.red{
        padding: 12px 16px;
        background-color: $black-c;
        border-color: $black-c;
        height: 46px;
        width: 46px;
        display: flex;
        align-items: center;
      }
      button {
        max-width: 46px;
        padding-left: 0;
        padding-right: 0;
        flex: 0 0 40px;
      }
    }
    @include media-breakpoint-down(lg) {
      .btn-contact-header {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      .logo {
        height: 40px;
        width: unset !important;

        p {
          height: 100%;
        }

        img {
          max-height: 100%;
          width: auto;
        }
      }
      .flex-wrapper-nav {
        .right-wrapper{
          gap: 15px;
        }
      }

      .search {
        flex: 1;
        order: 1;
        width: 100%;
        // flex-basis: 100%;
        // margin-top: 15px;
      }
    }
    @include media-breakpoint-down(xs) {
      .c-flex {
        padding: 15px 0;
      }
      .logo {
        flex-basis: 100%;
        text-align: center;
      }
      .flex-wrapper-nav {
        .right-wrapper{
          gap: 5px;
          .shop-cart{
            order: 2;
          }
        }
      }
      .search {
        .btn-t1.red {
          padding: 12px 15px;
          font-size: 1.125rem;
        }
      }
      .mobile-padding {
        padding: 0;
      }
    }
  }
}

.languages {
  .text-languages {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-transform: uppercase;
  }
  .icon-down {
    font-weight: 900;
    font-size: 16px;
  }

  .c-drop-content {
    width: auto !important;
    left: 0%;
    transform: translate(-20px, 13px);
  }

  .content-holder {
    padding: 20px;
    min-width: 183px;
    border: 1px solid $gray-c;
    background-color: $white-c;
  }
  .languages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  li {
    a {
      font-weight: 700;
      font-size: 18px;
      text-transform: none;
      color: $black-c;
      line-height: 22px;
    }

    &:hover a {
      color: $color1-c;
    }
  }
}

.user-logged {
  display: flex;
  flex-direction: column;
  gap: 18px;
  .c-drop-content {
    width: 200px !important;
  }

  .content-holder {
    padding: 20px;
    top: 45px;
    text-align: left;
    border: 1px solid $border-c !important;
  }

  li {
    a {
      font-weight: 700;
      font-size: 18px;
      text-transform: none;
      line-height: 22px;
      color: $black-c;
    }

    &:last-child a {
      color: $red-c;

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover a {
      color: $color1-c;
    }
  }
}

.account {
  & a {
    font-size: 16px !important;
  }
  .content-holder {
    padding: 20px;
    top: 45px;
    text-align: left;
    border: 1px solid $gray-c;
    background-color: $white-c;

    .wrapper-email_reset {
      display: flex;
      flex-direction: column;
      margin-top: 10px;

      label {
        color: #707070;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
      }
      input {
        margin-top: 0 !important;
      }
    }

    &.logged {
      min-width: 350px !important;
      padding: 10px;
      top: 45px;

      li {
        a {
          padding-left: 14px;
          font-weight: 600;
          padding-top: 5px;
          font-size: 18px;
          text-transform: none;
          color: $color2-c;
          position: relative;
          display: block;
          &:before {
            content: "\f105";
            position: absolute;
            left: 3px;
            top: 6px;
            font-family: FontAwesome;
          }
        }

        &:hover a {
          color: $color1-c;
        }
      }
    }
  }

  .heading {
    position: relative;
    padding: 0 0 10px 0;
    color: $color2-c;
    margin: 0;
    text-transform: uppercase;
    font-weight: 600;

    &:before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      width: 70px;
      height: 2px;
      background-color: $color2-c;
    }
  }

  .login-form-holder {
    @extend .clearfix;

    & .notification {
      margin-bottom: 10px;
    }

    .input-holder {
      border: 1px solid #ddd;
      margin-bottom: 10px;
      // height: 40px;
      // padding: 2px 2px 2px 31px;
      position: relative;
      // margin-bottom: 10px;
      // margin-top: 20px;

      input {
        margin-bottom: 0;
        border: 1px solid $gray-c;

        color: $color2-c;
        height: 40px;

        &::placeholder {
          font-weight: 300;
          font-size: 18px;
          line-height: 22px;
          color: $gray-c;
        }
      }
      // span {
      //   background-color: $color2-c;
      //   display: inline-block;
      //   width: 29px;
      //   height: 29px;
      //   color: #fff;
      //   position: absolute;
      //   left: 2px;
      //   top: 2px;
      //   text-align: center;
      //   line-height: 29px;
      //   z-index: 1;
      //   &:after {
      //     content: "";
      //     width: 0;
      //     height: 0;
      //     border-style: solid;
      //     border-width: 5px 0 5px 6px;
      //     border-color: transparent transparent transparent $color2-c;
      //     position: absolute;
      //     top: 50%;
      //     margin-top: -5px;
      //     left: 100%;
      //   }
      // }
    }
  }

  .wraper-forgot_pass {
    display: flex;
    gap: 20px;

    .forgotten-password,
    .register-link {
      color: $color1-c;
      text-decoration: underline;
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;

      &:hover {
        color: $black-c;
      }
    }
  }

  // .forgotten-password {
  //   color: $color2-c;
  //   margin-top: 20px;
  // }

  .wrapper-btn {
    margin-top: 20px;
    & button {
      width: 100%;
      font-weight: 500 !important;
      line-height: 22px !important;
    }
  }
}

.mobile-nav {
  .menu-block {
    display: none;
    // width: 40px;
    // height: 40px;
    background-color: transparent;
    border: 1px solid #282829;
    // padding: 8px;
    // padding: 9px 6px;
    padding: 8px;
    height: 46px;
    width: 46px;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  .account-wrapper-responsive {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      order: 1;
    }
  }
  .responsive-block {
    display: none;
    width: 40px;
    // position: absolute;
    // top: 131px;
    z-index: 13;
    // right: 110px;
    .phone {
      display: none;
    }
    .icon-phone {
      width: 40px;
      height: 40px;
      text-align: center;
      background-color: $color1-c;
      display: block;
      border: 2px solid $color1-c;
      .fa {
        color: $white-c;
        font-size: 1.75rem;
        line-height: 36px;
        width: 100%;
      }
      &:hover {
        background-color: $white-c;
        .fa {
          background-color: $white-c;
          color: $color1-c;
        }
      }
    }
  }
  .fix-search {
    display: none;
    .search {
      display: block !important;
      right: 0;
      width: 100%;
      position: absolute;
      margin: 0 !important;
      height: 40px;
      .search-preview {
        float: right;
        width: 40px;
        height: 40px;
        margin-top: -40px;
        margin-right: 60px;
        .icon {
          display: inline-block;
          width: 40px;
          height: 40px;
          padding-left: 0;
          padding-right: 0;
          font-size: 1.5rem;
        }
      }
    }
    .c-drop-holder.drop-open .c-drop-content {
      top: 10px !important;
    }
  }
  @include media-breakpoint-only(md) {
    .fix-search {
      .search {
        .search-preview {
          margin-right: 60px;
        }
      }
    }
  }
  @include media-breakpoint-only(sm) {
    position: relative;
    .fix-search {
      .search {
        .search-preview {
          .icon {
            padding: 5px 0;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    // display: block;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-direction: row-reverse;

    .icon-shopping-cart-mobile {
      font-size: 16px;
      padding: 13px;
    }
    // .wrapper-card_shop-responsive {
    //   position: relative !important;
    //   > div .shop-cart {
    //     position: absolute;
    //     top: 26px;
    //     right: 0px;
    //     left: 35px;
    //   }
    // }
  }
  @include media-breakpoint-down(xs) {
    gap: 5px;
    margin-left: 5px;
    .responsive-block {
      display: block;
    }
    .fix-search {
      display: block;
    }
  }
}
.fixed-header {
  .mobile-nav {
    .responsive-block {
      top: 10px;
      @include media-breakpoint-only(sm) {
        top: 0;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .responsive-block {
      display: block;
    }
    .fix-search {
      display: block;
    }
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fixed-header {
  padding-top: 67px;
  @include media-breakpoint-down(md) {
    padding-top: 52px;
  }
  .navigation {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-animation-duration: 600ms;
    animation-duration: 600ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    z-index: 10;
    background-color: $white-c;
    border-bottom: 1px solid #dddddd;
  }
}

//default style
.c-drop-holder {
  @include media-breakpoint-up(xs) {
    position: relative;
  }
  .c-drop-content {
    position: absolute;
    right: 0;
    top: 110%;
    width: 400px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    @include transition(opacity 0.6s, top 0.4s);

    // @include media-breakpoint-down(sm) {
    //   width: 320px;
    // }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  .c-drop-trigger {
    @extend .noselect;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
  }

  &.drop-open {
    .c-drop-content {
      top: 100%;
      opacity: 1;
      visibility: visible;
      z-index: 14;
    }
  }

  @include media-breakpoint-down(xs) {
    position: static;
    // .c-drop-content {
    //   width: 100% !important;
    // }
  }
}

.c-drop-holder {
  .c-drop-content {
  }
  .c-drop-trigger {
    font-size: 0.75rem;
  }
}
#display-mob {
  display: none !important;
}

@include media-breakpoint-down(md) {
  #hidden-mobile {
    display: none;
  }
  #display-mob {
    display: flex !important;
    background: $black-c;
    // display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    width: 46px;
    height: 46px;
    .icon-user {
      color: $white-c;
      font-size: 18px;
    }
  }
}

/* 
  SCROLL ICONS MOBILE
*/
.scroll-icons-container {
  @media (min-width: 991.98px) {
    display: none !important;
  }

  @media (max-width: 991px) {
    display: none;

    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    background-color: $white-c;

    .bg-red-wrapp {
      display: flex;
      width: 100%;
      gap: 1px;
      align-items: center;

      .icon-red {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: $red-c;
        justify-content: center;
        padding: 10px;

        > span {
          color: $white-c;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
          font-weight: 700;
        }
      }

      .wrapp-black-icons {
        display: flex;
        gap: 1px;

        .icon.email,
        .icon.facebook,
        .icon.instagram {
          height: 38px;
          width: 40px;
          display: flex;
          background-color: $black-c;
          align-items: center;
          justify-content: center;
          font-weight: 400;
        }
      }
    }
  }
}
