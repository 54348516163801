.shop-cart {
  width: 100%;

  .cart-preview {
    display: flex;

    .icon {
      // padding-left: 0;
      // padding-right: 0;
      // flex: 0 0 50px;
      // padding: 11px 0px;
      // font-size: 18px;
      flex: 0 0 46px;
      padding: 11px 0 !important;
      font-size: 18px;
    }
    .data {
      border: 1px solid $border-c;
      border-right: 0;
      line-height: 22px;
      text-transform: uppercase;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 12px;

      span.total-price {
        // color: $black-c;
        // font-weight: 400;
        font-size: 1.125rem;
        line-height: 22px;
      }
    }
    .items-qty {
      display: inline-block;
      margin-right: 10px;
    }
  }

  @include media-breakpoint-up(sm) {
    width: 190px;
  }

  // @media (min-width: 768px) and (max-width: 991px) {
  //   transform: translateX(46%);
  // }

  // @media (min-width: 576px) and (max-width: 767.98px) {
  //   .cart-preview {
  //     .icon {
  //       left: 100px !important;
  //       transform: translate(-25px, -8px);
  //     }
  //   }
  // }

  @media (max-width: 575.98px) {
    // #shop-header-position {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    // }
    // transform: translateX(40%);

    // left: auto;
    // right: 0;
    // width: 100% !important;
    // position: absolute !important;
    // top: 77px;
    // transform: translate(160px, 30px);
    // left: auto;
    // right: 0;
    // width: 100% !important;
    // transform: translateX(100px);
    // display: contents;
    // top: 77px;
    // margin-top: -51px;
    // margin-right: 10px;

    // #shop-cart {
    //   float: right;
    //   margin-top: -51px;
    //   margin-right: 10px;
    // }
    .cart-preview {
      width: 46px;
      height: 46px; //40px
      z-index: 10;
      display: inline-block;
      position: relative;
      // transform: translate(-100px, 5px);

      .icon {
        // display: inline-block;
        display: flex;
        width: 46px;
        height: 46px;
        font-size: 1.5rem;
        justify-content: center;
        align-items: center;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 80px;
        // right: 0;

        // @media (max-width: 480px) {
        //   left: 50px;
        // }

        // @media (max-width: 360px) {
        //   left: 30px;
        // }
      }
      .data {
        padding: 0;
        border: 0;
        width: 0;
        .icon-arrow {
          display: none;
        }
      }
      .items-qty {
        display: inline-block;
        border: 1px solid #ddd;
        background-color: #fff;
        margin: 0;
        min-width: 17px;
        height: 17px;
        line-height: 8px;
        padding: 4px;
        text-align: center;
        border-radius: 50%;
        top: -1px;
        right: -5px;
        position: absolute;

        span {
          display: none;
        }
      }
      .total-price {
        display: none;
      }
    }
  }
}

@-webkit-keyframes fadeInDownCart {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(310px, -100%, 0);
    transform: translate3d(310px, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(310px, 0, 0);
    transform: translate3d(310px, 0, 0);
  }
}

@keyframes fadeInDownCart {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(310px, -100%, 0);
    transform: translate3d(310px, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate3d(310px, 0, 0);
    transform: translate3d(310px, 0, 0);
  }
}

@include media-breakpoint-only(lg) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(225px, -100%, 0);
      transform: translate3d(225px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(225px, 0, 0);
      transform: translate3d(225px, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(225px, -100%, 0);
      transform: translate3d(225px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(225px, 0, 0);
      transform: translate3d(225px, 0, 0);
    }
  }
}
@include media-breakpoint-only(md) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(105px, -100%, 0);
      transform: translate3d(105px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(105px, 0, 0);
      transform: translate3d(105px, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(105px, -100%, 0);
      transform: translate3d(105px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(105px, 0, 0);
      transform: translate3d(105px, 0, 0);
    }
  }
}
@include media-breakpoint-only(sm) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(20px, -100%, 0);
      transform: translate3d(20px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(20px, 0, 0);
      transform: translate3d(20px, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(20px, -100%, 0);
      transform: translate3d(20px, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(20px, 0, 0);
      transform: translate3d(20px, 0, 0);
    }
  }
}
@include media-breakpoint-only(xs) {
  @-webkit-keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeInDownCart {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }

    100% {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.fadeInDownCart {
  -webkit-animation-name: fadeInDownCart;
  animation-name: fadeInDownCart;
}

.fixed-header {
  #shop-header-position {
    // -webkit-animation-duration: 600ms;
    // animation-duration: 600ms;
    // -webkit-animation-fill-mode: both;
    // animation-fill-mode: both;
    // -webkit-animation-name: fadeInDownCart;
    // animation-name: fadeInDownCart;
    // z-index: 10;
    // position: fixed !important;
    // left: 50%;
    // top: 90px;
    // @media (max-width: 767px) {
    // }

    .cart-preview {
      .icon {
        padding: 8px;
        font-size: 18px;
        text-align: center;
      }
    }

    .data {
      background-color: $white-c;
    }

    @include media-breakpoint-down(sm) {
      z-index: 12;
      width: 40px;
      display: contents;
      // top: 56px;
      // right: 10px;
      // #shop-cart {
      //   // float: right;
      //   // margin-top: -45px;
      //   // margin-right: 10px;
      // }
    }
    @include media-breakpoint-down(xs) {
      // left: auto;
      // right: 50px;
      width: 100% !important;
      // top: 84%;
    }
  }

  // @media (min-width: 577px) and (max-width: 767px) {
  //   .cart-preview {
  //     .icon {
  //       transform: translate(0px, -10px);
  //     }
  //   }
  // }

  @include media-breakpoint-down(sm) {
    .cart-preview {
      width: 46px;
      height: 46px;
      -webkit-animation-duration: 600ms;
      animation-duration: 600ms;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-animation-name: fadeInDown;
      animation-name: fadeInDown;
      z-index: 10;
      display: inline-block;

      .icon {
        display: inline-block;
        width: 46px;
        height: 46px;
        // transform: translate(0px, -8px);

        // @media (max-width: 480px) {
        //   transform: translate(0px, -1px);
        // }
      }
      .data {
        padding: 0;
        border: 0;
        width: 0;
        .icon-arrow {
          display: none;
        }
      }
      .items-qty {
        display: inline-block;
        border: 1px solid #ddd;
        background-color: #fff;
        margin: 0;
        min-width: 17px;
        height: 17px;
        line-height: 8px;
        padding: 4px;
        text-align: center;
        border-radius: 50%;
        top: -1px;
        right: -5px;
        position: absolute;

        span {
          display: none;
        }
      }
      .total-price {
        display: none;
      }
    }
  }
}

.shop-cart {
  @include media-breakpoint-up(sm) {
    position: relative !important;
  }

  .shop-cart-products {
    width: 300px;
    border: none;
    /* Firefox v3.5+ */
    -moz-box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.3);
    /* Safari v3.0+ and by Chrome v0.2+ */
    -webkit-box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.3);
    /* Firefox v4.0+ , Safari v5.1+ , Chrome v10.0+, IE v10+ and by Opera v10.5+ */
    box-shadow: -1px -1px 10px rgba(0, 0, 0, 0.3);
    -ms-filter: "progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true)";
    filter: progid:DXImageTransform.Microsoft.dropshadow(OffX=-1,OffY=-1,Color=#4d000000,Positive=true);
    background-color: $white-c;
    padding: 20px;
  }

  .cart-product-list {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  .product {
    // position: relative;
    // padding-left: 95px;
    // margin-bottom: 20px;

    position: relative;
    border: 1px solid $gray-c;
    display: flex;
    padding-right: 10px;
    flex-direction: row;
    gap: 10px;
    min-height: 80px;
    // width: 100%;
    // align-items: center;

    &:last-of-type {
      margin-bottom: 0;
    }

    .item-preview {
      // position: absolute;
      // left: 0;
      // top: 0;
      // display: inline-block;
      // width: 70px;
      // height: 90px;
      // border: 1px solid $gray-c;
      // box-sizing: content-box;

      // position: absolute;
      // left: 0;
      // top: 0;
      // display: inline-block;
      width: 80px;
      height: 80px;
      border-right: 1px solid $gray-c;
      box-sizing: content-box;
    }

    img {
      height: auto;
      width: auto;
      max-height: 100%;
      max-width: 100%;
    }

    .right-part {
      // position: relative;
      // padding-bottom: 60px;
      // min-height: 130px;
      // box-sizing: border-box;
      // padding-right: 10px;
      // padding-top: 20px;
      // text-align: center;

      position: relative;
      min-height: 70px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 100%;

      justify-content: center;
      align-items: baseline;

      & a:hover {
        color: $color1-c;
      }
    }

    .wraper-price-item {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      align-items: center;
    }

    h4 {
      // font-size: 14px;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      max-width: 250px;
      // text-align: center;
      // max-width: 150px;
      // min-height: 50px;
    }

    .product-features {
      font-size: 0.75rem;
      // margin-top: 10px;

      > span {
        display: block;
      }

      span.title {
        font-weight: 700;
        display: inline-block;
        // margin-right: 5px;
      }
    }

    .item-price {
      // position: absolute;
      // left: 0;
      // bottom: 10px;
      // display: block;

      // text-align: right;
      width: 100%;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      color: $color1-c;

      // .price_decimal {
      //   // font-size: 0.6em;
      //   position: relative;
      //   top: -0.6em;
      // }
      .price_currency {
        text-transform: uppercase;
      }
    }

    .item-cat {
      // position: absolute;
      // left: 0;
      // bottom: 10px;
      // display: block;
      font-weight: 400;
      font-size: 14px;
      color: #707070;
      line-height: 17px;
      &:hover {
        color: $color1-c;
      }
    }

    .remove-item {
      position: absolute;
      right: 0;
      top: 5px;
      color: $color1-c;
      font-weight: 900;
      line-height: 13px;

      &:hover {
        color: $black-c !important;
      }
    }

    // &:before {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   top: 100%;
    //   left: 0;
    //   height: 1px;
    //   background-color: $gray-c;
    // }
  }

  .shop-cart-total {
    line-height: 60px;
    text-align: center;
    color: $white-c;
    background-color: $color2-c;
    font-size: 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: 700;

    // .title {
    // }

    & .price_decimal {
      font-size: 0.6em;
      position: relative;
      top: -0.7em;
    }
    & .price_currency {
      font-weight: 600;
      text-transform: lowercase;
    }
  }

  .shop-cart-buttons {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;

    .cart-order {
      width: 100%;

      & a {
        width: 100%;
        padding: 12px 24px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
      }

      & .button-change {
        color: $color1-c;
        border: 1px solid $color1-c;
        background-color: transparent;
      }
    }
  }
}
