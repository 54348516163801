.gallery-on-page {

  margin-top: 40px;
}

.gallery-album{
  margin-top: 30px;
}

.gallery-album-item {
  margin-bottom: 30px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  .text{
    text-align: center;

    h3{
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      height: 50px;
      margin-top: 5px; margin-bottom: 5px;
      > a{
        display: block;
        position: relative;
        @include translate(0, -50%);
        top: 50%;
      }
    }
  }
}

.gallery-item-thumb {
  position: relative;
  background-color: $gray-c;
  overflow: hidden;
  -webkit-backface-visibility: hidden;

  > a{
    display: block;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
    opacity: 0;

    span{
      display: inline-block;
      position: absolute;
      top: 50%; left: 50%;
      color: $white-c;
      font-size: 22px;
      @include translate(-50%, -50%);
    }

    &:before {
      content: '';
      position: absolute;
      top: 10px; right: 10px; bottom: 10px; left: 10px;
      border: 1px solid $border-c;
      pointer-events: none;
    }
  }
}
.gallery-album-item:hover .gallery-item-thumb > a{
  opacity: 1;
}

.gallery-album-item img{
  @include transition-all(0.4s);
}

.gallery-album-item:hover img{
  -ms-transform: translate3d(-50%, -50%, 0) scale(1.1, 1.1);
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1.1, 1.1);
  transform: translate3d(-50%, -50%, 0) scale(1.1, 1.1);
}

@include media-breakpoint-only(xs)          { .gallery-album > div:nth-of-type(2n+1){ clear: both; } }
@include media-breakpoint-between(sm, md)   { .gallery-album > div:nth-of-type(3n+1){ clear: both; } }
@include media-breakpoint-up(lg)            { .gallery-album > div:nth-of-type(4n+1){ clear: both; } }

