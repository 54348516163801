.banners-below-slider {
  padding-top: 100px;

  .border-wrapper {
    position: relative;

    @media (min-width: 992px) {
      &::before {
        border-left: 4px solid $red-c;
        border-right: 4px solid $red-c;
        content: "";
        position: absolute;
        height: calc(100% - 11px);
        left: -20px;
        right: -20px;

        @media (max-width: 575.98px) {
        }
      }
    }
    @media (max-width: 1199.98px) {
    }

    .wrapper-banner-home {
      position: relative;
      width: 100%;

      .slick-list {
        .slick-track {
          .slick-slide {
            margin: 0 5px;
            @media (max-width: 575.98px) {
              display: flex;
              justify-content: center;
              width: 100%;
            }
            .slick-list {
              margin: 0 -5px;
            }
          }
        }
      }

      ul.slick-dots {
        display: flex;
        gap: 2px;
        justify-content: center;
        align-items: center;
        position: absolute;
        transform: translate(-50%, 60px);
        bottom: 30px;
        left: 50%;
        li {
          > button {
            background-color: $gray-c;
            width: 20px;
            height: 4px;
            border: 0;
            transition: background-color 0.8s ease-in-out,
              width 0.8s ease-in-out;
          }
          &.slick-active {
            > button {
              background-color: $red-c;
              width: 40px;
            }
          }
        }
      }
    }
  }
}

/* DISCOUNTS */
.banner-discount {
  padding: 42px 0;
  background-color: #f4f4f4;

  .c-flex {
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .image-block {
    flex: 5;
    text-align: center;
  }

  .text-block {
    text-transform: uppercase;
    font-weight: 700;
    flex: 7;
    > span {
      display: block;
    }
  }

  .text1 {
    font-size: 16px;
  }
  .text2 {
    font-size: 42px;
    color: $color1-c;
  }
  .text3 {
    font-size: 42px;
    display: block;
    text-align: right;
    span {
      display: inline-block;
      position: relative;
      padding-left: 30px;
      &:before {
        content: "\f061";
        font-family: FontAwesome;
        position: absolute;
        left: 0;
        top: 50%;
        @include translate(0, -50%);
        font-size: 20px;
      }
    }
    p {
      margin-bottom: 0;
      display: inline-block;
    }
  }
  .link {
    margin-top: 20px;
  }

  @include media-breakpoint-only(md) {
    .text1 {
      font-size: 14px;
    }
    .text2 {
      font-size: 32px;
    }
    .text3 {
      font-size: 32px;
    }
  }
  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }

  @include media-breakpoint-down(sm) {
    .text1 {
      font-size: 12px;
    }
    .text2 {
      font-size: 21px;
    }
    .text3 {
      font-size: 21px;
      span {
        padding-left: 20px;
        &:before {
          font-size: 14px;
        }
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .c-flex {
      @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .text-block {
      padding-left: 40px;
    }
  }

  @include media-breakpoint-only(xs) {
    .image-block {
      img {
        max-width: 200px;
        margin-bottom: 20px;
      }
    }
  }
}

.banner-discount-wrapper {
  padding-bottom: 100px;

  .bg-image-baner {
    min-height: 497px;
    position: relative;

    background-position: center;
    background-size: cover;

    @include media-breakpoint-down(sm) {
      min-height: 628px;
      background-size: inherit;
    }

    .bg-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        360deg,
        transparent,
        transparent,
        $black-c
      );
      width: 100%;
      content: "";
      height: 100%;
      // z-index: 1;
    }

    .flex-wrapper-text {
      display: flex;
      width: 100%;
      gap: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 80px 0px;

      @include media-breakpoint-down(sm) {
        padding: 60px 0px;
      }

      .text-block-wrapper {
        z-index: 1;
        font-weight: 400;
        line-height: 29px;
        font-size: 18px;
        color: $gray-c;
        text-align: center;
        max-width: 629px;

        @include media-breakpoint-down(sm) {
          max-width: 320px;
        }
      }

      .button-wrapper {
        z-index: 1;
        > a {
          display: flex;
          align-items: center;
          gap: 10px;
          text-transform: math-auto;
          cursor: pointer;

          .arrow-right-red {
            display: none;
          }

          &:hover {
            .arrow-right-white {
              display: none;
            }
            .arrow-right-red {
              display: block;
            }
          }
        }
      }
    }
  }
}

.banner-our-service-wraper {
  padding-bottom: 100px;

  .bg-image-baner {
    position: relative;
    // background-color: black;
    // min-height: 336px;

    // background-position: center;
    // background-size: cover;

    @include media-breakpoint-down(sm) {
      background-size: inherit;
    }

    .bg-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      // background-image: linear-gradient(
      //   360deg,
      //   transparent,
      //   transparent,
      //   #1b1918
      // );
      background-image: linear-gradient(360deg, $black-c, $black-c, $black-c);
      width: 100%;
      content: "";
      height: 100%;
    }

    .wraper-flex-card {
      display: flex;
      width: 100%;

      @media (max-width: 991.98px) {
        flex-direction: column;
      }

      .service-item-wraper {
        display: flex;
        flex-direction: column;
        padding: 40px 0px;
        padding-left: 20px;
        border-right: 1px solid $gray-c;
        cursor: pointer;
        position: relative;

        &:last-child {
          border-right: none;
        }

        @media (min-width: 992px) and (max-width: 1199.98px) {
          &:first-child {
            padding-left: 0px;
          }
        }

        @media (max-width: 991.98px) {
          border: none;
          padding-left: 0px;
        }

        .bg {
          display: block;
          height: 100%;
          width: 100%;
          background: rgba(27, 25, 24, 0.5);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          opacity: 0;
        }

        .service-img {
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          width: 100%;
          height: 100%;
          // z-index: -1;
          opacity: 0;
          transition: opacity 0.8s ease-in-out;
        }

        .service-c {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: baseline;
          flex: 1;
          z-index: 999999;

          .icon-display {
            p {
              opacity: 1 !important;
            }
          }

          h2 {
            font-size: 23px;
            line-height: 29px;
            font-weight: 500;
            max-width: 320px;
            min-height: 10px;
            flex: 1;
            white-space: nowrap;
            color: $white-c;
            // padding-top: 20px;

            @media (min-width: 992px) and (max-width: 1199.98px) {
              white-space: normal;
            }
          }

          p {
            font-weight: 300;
            font-size: 15px;
            line-height: 26px;
            max-width: 320px;
            color: $gray-c;
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
          }

          .btn-service {
            color: $white-c;
            line-height: 22px;
            font-size: 18px;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 8px;
          }
          @media (max-width: 991.98px) {
            padding: 0px 40px;
          }
        }

        &:hover {
          .bg {
            opacity: 1;
          }
          .service-img {
            opacity: 1;
          }
          .service-c {
            p {
              opacity: 1;
            }
          }
        }
        @include media-breakpoint-down(md){
          .service-img {
            opacity: 1;
          }
          .service-c{
            p{
              min-height: 80px;
              opacity: 1;
            }
          }
          .bg{
            opacity: 1;
          }
        }
      }

      // .service-item-wraper:nth-child(1)::before {
      //   content: "";
      //   width: 47px;
      //   height: 33px;
      //   background-repeat: no-repeat;
      //   background-image: url("../images/icon-8.svg");
      //   background-size: contain;
      //   z-index: 99999;
      // }
      // .service-item-wraper:nth-child(2)::before {
      //   content: "";
      //   width: 47px;
      //   height: 33px;
      //   background-repeat: no-repeat;
      //   background-image: url("../images/icon-9.svg");
      //   background-size: contain;
      //   z-index: 99999;
      // }
      // .service-item-wraper:nth-child(3)::before {
      //   content: "";
      //   width: 47px;
      //   height: 33px;
      //   background-repeat: no-repeat;
      //   background-image: url("../images/icon-10.svg");
      //   background-size: contain;
      //   z-index: 99999;
      // }

      .slick-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        .slick-track {
          display: flex;
        }
      }

      ul.slick-dots {
        display: flex;
        gap: 2px;
        justify-content: center;
        align-items: center;
        position: absolute;
        // transform: translateX(-50%);
        transform: translate(-50%, 60px);
        bottom: 20px;
        left: 50%;

        li {
          > button {
            background-color: $gray-c;
            width: 20px;
            height: 4px;
            border: 0;
            transition: background-color 0.8s ease-in-out,
              width 0.8s ease-in-out;
          }
          &.slick-active {
            > button {
              background-color: $red-c;
              width: 40px;
            }
          }
        }
      }
    }
  }
}
