// ***********************************
// VARIABLES
// ***********************************

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/oswald/Oswald-ExtraLight.eot');
  src: local(""),
      url('../fonts/oswald/Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/oswald/Oswald-ExtraLight.woff2') format('woff2'),
      url('../fonts/oswald/Oswald-ExtraLight.woff') format('woff'),
      url('../fonts/oswald/Oswald-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/oswald/Oswald-Light.eot');
  src: local(""),
      url('../fonts/oswald/Oswald-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/oswald/Oswald-Light.woff2') format('woff2'),
      url('../fonts/oswald/Oswald-Light.woff') format('woff'),
      url('../fonts/oswald/Oswald-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/oswald/Oswald-Medium.eot');
  src: local(""),
      url('../fonts/oswald/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/oswald/Oswald-Medium.woff2') format('woff2'),
      url('../fonts/oswald/Oswald-Medium.woff') format('woff'),
      url('../fonts/oswald/Oswald-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/oswald/Oswald-Bold.eot');
  src: local(""),
      url('../fonts/oswald/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/oswald/Oswald-Bold.woff2') format('woff2'),
      url('../fonts/oswald/Oswald-Bold.woff') format('woff'),
      url('../fonts/oswald/Oswald-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/oswald/Oswald-Regular.eot');
  src: local(""),
      url('../fonts/oswald/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/oswald/Oswald-Regular.woff2') format('woff2'),
      url('../fonts/oswald/Oswald-Regular.woff') format('woff'),
      url('../fonts/oswald/Oswald-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('../fonts/oswald/Oswald-SemiBold.eot');
  src: local(""),
      url('../fonts/oswald/Oswald-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/oswald/Oswald-SemiBold.woff2') format('woff2'),
      url('../fonts/oswald/Oswald-SemiBold.woff') format('woff'),
      url('../fonts/oswald/Oswald-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

// TYPOGRAPHY
$font-family1: "Oswald", sans-serif;

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $font-size-h1;
}
h2,
.h2 {
  font-size: $font-size-h2;
}
h3,
.h3 {
  font-size: $font-size-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
}
h6,
.h6 {
  font-size: $font-size-h6;
}

// COLORS
$white-c: #ffffff; // #fff
$black-c: #474747; // #333
$gray-c: #dddddd;
$red-c: #ba1b2e; // #e5514b
$green-c: #d9d900;
$blue: #258dc9;

$text-c: $black-c;
$rectangle-c: $black-c;
$border-c: $gray-c;

$color1-c: $red-c;
$color2-c: $black-c;

$alert-color: $red-c;
$success-color-c: $green-c;
