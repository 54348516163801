html,
body {
  height: 100%;
}

html {
  overflow: visible !important;
}

@include media-breakpoint-only(xs) {
  html {
    font-size: 14px;
  }
}

@include media-breakpoint-down(sm) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

body {
  font-family: $font-family1;
  font-size: 0.875rem;
  color: $text-c;
  overflow-x: hidden;
  p{
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.6875rem;
    margin-bottom: 1.25rem;
  }
}

.page-padding {
  padding: 40px 0;
  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }
}
.fs-1 {
  font-size: 1rem;
}
.flex-block {
  display: flex;
  align-items: center;
}
.section-title-t1 {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  color: $text-c;
  margin-bottom: 40px;
  span {
    display: inline-block;
    position: relative;
    padding-bottom: 12px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 4px;
      background-color: $color1-c;
    }

    // padding: 0 5px;

    // &:before {
    //   content: '';
    //   position: absolute;
    //   top: 50%; right: 100%;
    //   margin-right: 5px;
    //   width: 2000px; height: 1px;
    //   background-color: $border-c;
    // }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 50%; left: 100%;
    //   margin-left: 5px;
    //   width: 2000px; height: 1px;
    //   background-color: $border-c;
    // }

    // @include media-breakpoint-down(md) {
    //   &:before {
    //     display: none;
    //   }
    //   &:after {
    //     display: none;
    //   }
    // }
  }

  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}

.section-title-t2 {
  // text-transform: uppercase;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 40px;
  line-height: 44px;
  color: $text-c;
  span {
    display: inline-block;
    position: relative;
    padding-bottom: 25px;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 90px;
      height: 1px;
      background-color: $color2-c;
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 24px;
  }
}
.section-title-t3 {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  line-height: 44px;
  color: $text-c;
  margin-bottom: 40px;
  span {
    display: inline-block;
    position: relative;
    padding-bottom: 12px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 4px;
      background-color: $color1-c;
    }
  }

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
}
.section-subtitle-t1 {
  text-align: center;
  // text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 4px;
  color: #aaa;
}
.section-title-b1{
  font-size: 2.25rem;
  color: #1B1918;
  text-align: center;
  font-weight: 700;
  margin-bottom: 40px;
  span{
    color: #BA1B2E;
  }
}
@include media-breakpoint-up(sm) {
}

@include media-breakpoint-between(sm, lg) {
}

@include media-breakpoint-down(lg) {
}

.image-wrapper {
  // Adding a loader and background color. The user will see it
  // if the image is loading slow.
  background: #ffffff url("../images/loader.svg") center center no-repeat;
  width: 100%;
}

.ratio_1-1 {
  padding-bottom: 100%;
  height: 0;
}
.ratio_1-77 {
  padding-bottom: 77%;
  height: 0;
}
.ratio_3-4 {
  // The image has a 16/9 ratio. Until the image has loaded
  // we need to reserve some space so the page won't reflow.
  // How to calculate the space (padding-bottom): 9/16*100 = 56.25
  // Another example: you have an image 400x250.
  // So if you want to calculate the space you do: 250/400*100 = 62.5
  padding-bottom: 133.33333333%;
  height: 0;
}

.ratio_2-1 {
  // The image has a 16/9 ratio. Until the image has loaded
  // we need to reserve some space so the page won't reflow.
  // How to calculate the space (padding-bottom): 9/16*100 = 56.25
  // Another example: you have an image 400x250.
  // So if you want to calculate the space you do: 250/400*100 = 62.5
  padding-bottom: 50%;
  height: 0;
}

.b-lazy {
  max-width: 100%;
  -webkit-transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
  opacity: 0;
}
.b-lazy.b-loaded {
  opacity: 1;
}

.webpage-overlay {
  opacity: 0;
  visibility: hidden;
  top: -100%;
}

/*AUTOCOMPLETE INPUT*/
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  z-index: 99999;
}

.ui-widget-content {
  border: 1px solid $black-c;
  background-color: $white-c;
}
.ui-menu .ui-menu-item {
  padding: 5px 10px;
  cursor: pointer;
  border: 0;
  color: $black-c;
  background-color: $white-c;
  font-size: 12px;
  margin: 0;

  &:hover {
    color: $white-c;
    background: $black-c !important;
  }
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  border-bottom: 0;
  color: $white-c;
  background: $black-c !important;
  font-weight: normal;
  margin: 0;
}
/* Loader on ordering page */
.loader-holder {
  margin-top: 25px;
  //padding: 0 87px;
  .notification-send {
    font-size: 1rem;
    color: $red-c;
    margin-right: 15px;
    font-weight: 700;
  }
}
.loader-hidden {
  display: none !important;
}

/* Google ReCaptcha */
.recaptcha-box {
  display: inline-block;
  transform: scale(0.65);
  -webkit-transform: scale(0.65);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  width: 198px;
  height: 80px;
  z-index: 1;
  position: relative;
  padding-top: 25px;
}
.margin-top-min-15 {
  margin-top: -15px;
}
