.registration-block {
  & input::placeholder {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: $gray-c;
  }
  & input,
  select {
    border: 1px solid $gray-c;
  }

  .note {
    // margin-bottom: 40px;
    color: #707070;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    .c-important {
      color: $color1-c;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }

    .fa {
      color: $color1-c;
      position: relative;
      top: -10px;
      font-size: 12px;
      line-height: 22px;
    }
  }

  .personal-data-title {
    font-size: 24px;
    line-height: 29px;
    // padding: 10px 0;
    // margin-top: 20px;
    text-transform: uppercase;
    font-weight: 700;
    color: $color1-c;
  }

  .note-email {
    color: #707070;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    padding-top: 30px;

    .c-important {
      color: $color1-c;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .user-type {
    > div:first-of-type {
      margin-left: 30px;
    }
  }

  & .wrapper-select-person {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 575.98px) {
      flex-direction: column;
      justify-content: center;
    }

    h3 {
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
      font-weight: 700;
      color: $color1-c;
      padding-top: 20px;
    }

    .radio-box {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .first-block {
    #company-data-holder {
      border-bottom: 1px solid $gray-c;
      padding-bottom: 20px;
    }
  }

  .second-block {
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
      position: static;

      .bottom-block {
        position: absolute;
        bottom: 6%;
        right: 15px;

        & .email_notification_note {
          color: #707070;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }

        & .terms-of-use {
          color: #707070;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;

          > a {
            color: $red-c !important;
            font-style: normal;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }
}
