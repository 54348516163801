.product-details{
  margin-bottom: 80px;
}
.product-options {
  display: flex;
  flex-direction: column;
  row-gap: 0px;

  // @media (max-width: 767.98px) {
  //   row-gap: 0px;
  // }

  .block-title {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.875rem;
  }

  .product-header {
    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      // text-transform: uppercase;
      margin: 0;
    }

    .price-block {
      font-weight: 700;
      padding: 10px 0;
      border-bottom: 1px solid $gray-c;

      .wrapper-price_cart-add {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767.98px) {
          flex-direction: column;
        }

        & div .price {
          font-weight: 700;
          color: $black-c;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
        }
        .p-note{
          margin: 0;
          font-size: 0.75rem;
        }
      }

      .price {
        display: inline-block;
        color: $black-c;
        font-size: 18px;
        // margin: 0 5px;
        font-weight: 400;
        line-height: 25px;

        & .color-red {
          color: $color1-c;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
        }
      }

      .old-price {
        display: inline-block;
        padding: 0 5px;
        position: relative;
        color: #707070;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: #707070;
        }
      }
    }

    > .flex-col {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    .description {
      padding: 1.25rem 0;
      border-bottom: 1px solid $gray-c;

      & .description-title {
        text-transform: uppercase;
        display: block;
        font-weight: 700;
        margin-bottom: 0.875rem;
        color: $black-c;
        font-size: 16px;
        line-height: 24px;
      }

      p {
        margin-bottom: 0;
        color: #707070;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .wrapper-favorite_share {
      padding: 20px 0px;
      // border-bottom: 1px solid $gray-c;
      display: flex;
      width: 100%;
      justify-content: space-between;

      @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        justify-content: center;
      }

      .product-links-holder {
        // padding-top: 1.25rem;

        ul {
          li {
            float: left;
            margin-right: 10px;

            &:last-child {
              margin-right: 0;
            }

            a {
              display: inline-block;
              text-decoration: none;
              padding-left: 50px;
              // padding-inline: 30px;
              position: relative;
              line-height: 35px;
              font-size: 18px;
              font-weight: 400;
              color: #707070;
              text-align: center;

              transition: color 0.6s ease-out, border-color 0.6s ease-in-out,
                background-color 0.6s ease-in-out;

              span {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 18px;
                height: 40px;
                width: 40px;

                line-height: 29px;
                border: 1px solid $gray-c;
                // display: inline-block;
                // vertical-align: middle;

                font-weight: 400;
                color: #707070;
                @include transition-all(0.4s);

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
              }

              &:hover {
                color: $color1-c;

                span {
                  border-color: $color1-c;
                  background-color: $color1-c;
                  color: $white-c;
                }
              }
            }
          }
        }
      }

      .product-footer {
        // padding: 1.25rem 0;
        // border-bottom: 1px solid $gray-c;

        .categories-holder {
          a {
            display: inline-block;

            &:hover {
              color: $color1-c;
            }
          }
        }

        .social-links-holder {
          display: flex;
          align-items: center;
          gap: 10px;

          @media (max-width: 767.98px) {
            flex-direction: column;
          }

          & span {
            color: #707070;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
          }

          .social-links {
            display: block;
            @extend .clearfix;

            li {
              display: inline-block;
              float: left;

              // &:not(:first-of-type) {
              //   a {
              //     border-left: 0;
              //   }
              // }
            }

            a {
              display: block;
              // line-height: 38px;
              width: 40px;
              border: 1px solid $border-c;
              color: #707070;
              text-align: center;
              font-size: 18px;
              font-weight: 400;
              line-height: 20px;
              padding: 8px;
              transition: border 0.6s ease-in-out;

              &:hover {
                // border-color: $color1-c;
                // background-color: $black-c;
                // color: $white-c !important;
                border: 1px solid $red-c;
              }
            }
          }
        }
      }
    }
  }
  .boxed {
    .flex-b {
      display: flex;
      align-items: center;
      flex-wrap: wrap; 
      gap: 10px;
    }
    .i-block{
      margin-bottom: 20px;
    }
    .input-w {
      position: relative;
    }
    .form-input {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      -webkit-appearance: none;
      appearance: none;
      z-index: 1;
      cursor: pointer;
    }
    .name-t{
      text-transform: uppercase;
      display: block;
      font-weight: 600;
      margin-bottom: 10px;
      color: $black-c;
      font-size: 1.125rem;
    }
    label {
      display: inline-block;
      padding: 10px;
      border:  1px solid #707070;
      transition: all 0.3s;
      margin: 0;
    }
    input[type="radio"] {
      //display: none;
    }
    input[type="radio"]:checked + label {
      border:  1px solid #BA1B2E;
      background-color: #BA1B2E;
      color: $white-c;
    }
  }
  .message_block{
    color: #ba1b2e;
  }
  .product-cart {
    padding: 1.25rem 0;



    .send-request{
      border: 1px solid $red-c;
      font-size: 1.125rem;
      color: $red-c;
      text-transform: uppercase;
      padding: 9px 21px;
    }
    .selectboxes-holder {
      .select {
        margin-bottom: 1.25rem;
      }
    }
  }
}
.info-text{
  font-size: 0.875rem;
  margin-bottom: 0;
}
.send-to-friend {
  text-align: left;

  .sent-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .sent-subtitle {
    font-size: 1rem;
  }

  .c-textarea {
    min-height: 120px;
    padding: 10px 15px;
  }
}

.modal-send-to-friend {
  .close {
    position: absolute;
    right: -17px;
    top: -17px;
    width: 45px;
    height: 45px;
    text-indent: -99999px;
    opacity: 0.85;
    background: url("../images/close-quick.png");
    border: none;
    cursor: pointer; 
  }
}

.counter-holder {
  position: relative;
  padding: 0 0 0 107px;

  & .product-counter {
    border: 1px solid $gray-c;
    padding-right: 30px;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 90px;

    input {
      border: 0 none;
      float: left;
      line-height: 25px;
      padding: 9px 0;
      text-align: center;
      width: 58px;
      color: #707070;
      font-weight: 700;
    }

    div {
      border-left: 1px solid $gray-c;
      cursor: pointer;
      font-size: 15px;
      line-height: 100%;
      padding: 3px 0;
      position: absolute;
      right: 0;
      text-align: center;
      width: 30px;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
      background-color: $color2-c;
      color: $white-c;
      &:hover {
        background-color: $color1-c;
      }
      &.addQuantity {
        border-bottom: 1px solid $gray-c;
        top: 0;
      }
      &.minusQuantity {
        bottom: 0;
      }
    }
  }

  .large-button {
    font-size: 18px;
    text-transform: uppercase;
    white-space: nowrap;
    color: $white-c;
    background-color: $color1-c;
    text-align: center;
    line-height: 22px;
    display: block;
    width: 100%;
    padding: 10px 24px;
    font-weight: 400;
    border: 1px solid transparent;
    cursor: pointer;
    @include transition-all(0.4s);
    &:hover {
      color: $color1-c;
      border: 1px solid $color1-c;
      background-color: $white-c;
    }

    @media (max-width: 575.98px) {
      font-size: 11px;
    }
  }
}

.not-in-stock {
  font-size: 1rem;
  font-weight: bold;
  color: $color1-c;
  padding-bottom: 20px;
  border-bottom: 1px solid $gray-c;
}

@include media-breakpoint-down(md) {
  .product-options {
    margin-top: 30px;
  }
}

@include media-breakpoint-down(sm) {
  .best-sellers {
    margin-top: 30px;

    .text-block {
      padding-right: 10px;
    }
  }
}

.product-category-page {
  // https://images.unsplash.com/photo-1611186871348-b1ce696e52c9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTA1fHxwcm9kdWN0fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60
  .title-parallax {
    // background-size: cover;
    // background-position: center;
    // background-attachment: fixed;

    // .bg-overlay {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   // background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
    //   width: 100%;
    //   content: "";
    //   height: 100%;
    // }
  }

  .filter-title-b {
    display: none;
    &.active {
      .plus-minus:after {
        @include transform(rotate(0deg));
        @include transition-all(0.4s);
      }
    }

    .filter-title {
      font-size: 1.5rem;
      color: $white-c;
      background-color: $black-c;
      font-weight: 400;
      padding: 6px 20px;
      line-height: 30px;
      margin: 0;
      cursor: pointer;
      position: relative;

      .plus-minus {
        cursor: pointer;
        position: absolute;
        right: 3px;
        top: 4px;
        width: 30px;
        height: 30px;
        display: inline-block;
        padding: 0;
        //    border-bottom: 1px solid $white-c;
        z-index: 1;

        &:before {
          content: "";
          position: absolute;
          width: 17px;
          height: 3px;
          right: 7px;
          @include center(y);
          background-color: $white-c;
        }
        &:after {
          content: "";
          position: absolute;
          width: 17px;
          height: 3px;
          right: 7px;
          @include center(y);
          @include transform(rotate(90deg));
          background-color: $white-c;
          margin-top: -1px;
          @include transition-all(0.4s);
        }
        &.trigger-active {
          &:after {
            @include transform(rotate(0deg));
            @include transition-all(0.4s);
          }
        }
      }
      @include media-breakpoint-down(sm) {
        display: block;
        margin-bottom: 10px;
      }
    }
    .sort-block {
      display: flex;
    }
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .filter-title-box {
    // margin-top: 10px;
    &.active {
      display: block;
    }
  }
  .sidebar-block {
    margin-bottom: 30px;

    .block-title {
      font-size: 1.125rem;
      font-weight: 700;
      text-transform: uppercase;
      color: $white-c;
      background-color: $black-c;
      padding: 10px;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(xs) {
      #categories {
        .block-title {
          margin-top: 10px;
        }
      }
    }
  }
  .product-list-options-trigger {
    display: none;

    font-size: 1.5rem;
    color: $black-c;
    background-color: $white-c;
    border: 1px solid $black-c;
    padding: 5px 20px;
    line-height: 30px;
    margin: 0;
    cursor: pointer;
    position: relative;
    margin-bottom: 25px;

    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 3px;
      top: 4px;
      width: 30px;
      height: 30px;
      display: inline-block;
      padding: 0;
      //    border-bottom: 1px solid $white-c;
      z-index: 1;

      &:before {
        content: "";
        position: absolute;
        width: 17px;
        height: 3px;
        right: 7px;
        @include center(y);
        background-color: $black-c;
      }
      &:after {
        content: "";
        position: absolute;
        width: 17px;
        height: 3px;
        right: 7px;
        @include center(y);
        @include transform(rotate(90deg));
        background-color: $black-c;
        margin-top: -1px;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(0deg));
          @include transition-all(0.4s);
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .filter-title-box {
      display: none;
      &.class1 {
        display: block;
      }
    }
    .product-list-options-trigger {
      display: block;
      width: 100%;
      flex: 1;
      margin-right: 10px;
      margin-bottom: 0;
    }
    .product-category-list-options {
      display: none;
      margin-bottom: 10px;
      .select {
        margin-bottom: 10px;
      }
    }
  }
}

.sidebar-filters {
  border-bottom: 1px solid $gray-c;
  .filters-title {
    font-weight: 400;
    position: relative;
    padding: 10px 30px 10px 10px;
    margin-bottom: 0;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 30px;
    font-size: 0.875rem;
    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 10px;
      width: 25px;
      height: 25px;
      display: inline-block;
      padding: 0;
      //    border-bottom: 1px solid $white-c;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 2px;
        right: 7px;
        @include center(y);
        background-color: $black-c;
      }
      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 2px;
        right: 7px;
        @include center(y);
        @include transform(rotate(90deg));
        background-color: $black-c;
        margin-top: -1px;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(0deg));
          @include transition-all(0.4s);
        }
      }
    }
  }
  .checkboxes-holder {
    padding: 5px 10px 15px 10px;
    label {
      text-align: left !important;
    }
  }
}

.sidebar-filters-options {
  margin-top: 40px;
  a {
    display: block;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    // padding: 12px 24px;
    // transition: ;
  }

  #btn-resetFil {
    border: 1px solid $red-c !important;
    background-color: $white-c !important;
    color: $red-c !important;
  }
}

/* Categories */
.categories-block {

  > ul {
    border-bottom: 1px solid $gray-c;
  }

  li {
    position: relative;
    display: block;
    border-bottom: 1px solid $gray-c;
  }

  a {
    display: block;
    padding: 20px 54px 20px 10px;
    line-height: 22px;
    font-size: 18px;
    font-weight: 400;
    color: $black-c;
    transition: color 0.4s ease-in-out;
    &:hover,
    &:focus {
      color: $red-c;
      text-decoration: none;
    }

    img {
      max-width: 30px;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .active > a {
    color: $color1-c;
    // &:hover,
    // &:focus {
    //   color: $color1-c;
    // }
  }

  i {
    position: absolute;
    @include center(y);
    right: 10px;
  }

  

  ul > li {
    position: relative;
    
    .submenu-trigger{
      position: absolute;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 12px;
      cursor: pointer;
      &:before {
        content: "\f054";
        font-size: 18px;
        font-family: FontAwesome;
        color: $black-c;
        // font-weight: 900;
        // transform: translateY(-50%);
        transition: all 0.7s ease-in-out;
      }
    }
    > ul {
      display: none;
      position: relative;
      background-color: #fff;
      flex-direction: column;
      gap: 20px;
      // padding-top: 10px;
      overflow: hidden;
      padding: 0 14px;
      > li{
        border-bottom: unset;
        a{
          padding: 10px;
        }
        .submenu-trigger{
          top: 1px;
        }
      }
      li:last-of-type {
        border: unset;
      }
    }


    &:hover {
      a {
        color: $red-c;
      }
    }
  }
  .clicked {
    border-bottom: 0;
    padding-bottom: 10px;
    a {
      color: $red-c;
    }
    .submenu-trigger::before {
      content: "\f078";
    }
    > ul {
      > li {
        & a {
          color: #707070 !important;
          &:hover {
            color: $red-c !important;
          }
        }
      
      }
    }
 
  }
}

.view-type {
  display: block;
  text-align: right;

  li {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 20px;

    a {
      line-height: 38px;
      display: block;
      text-align: center;
      border: 1px solid $border-c;
      &.active {
        border-color: $color1-c;
        background-color: $color1-c;
        color: $white-c;
      }
    }

    &.view-grid {
      margin-right: 6px;
    }
  }
}
.categories-big {
  margin-bottom: 15px;
  .button-filter {
    font-size: 1.5rem;
    background-color: #333;
    padding: 9px 20px;
    line-height: 30px;
    margin: 0;
    color: #fff;
    cursor: pointer;
    margin-bottom: 20px;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
.product-category-list-options {
  @extend .clearfix;
  margin-bottom: 40px;

  @include media-breakpoint-only(xl) {
    .pos-helper {
      position: relative;
      padding-right: 120px;

      .view-type-holder {
        width: 90px;
        position: absolute;
        right: -105px;
        top: 0;
        padding: 0;
      }
    }
  }

  .border {
    @extend .clearfix;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-c;
  }

  @include media-breakpoint-down(md) {
    .border {
      padding-bottom: 0px;
    }
  }
  @include media-breakpoint-down(sm) {
    .pos-helper {
      .view-type-holder {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .select {
      margin-bottom: 20px;
    }
  }
}

.product-category-list {
  > div {
    @include media-breakpoint-down(md) {
      &:nth-of-type(2n + 1) {
        clear: both;
      }
    }
    @include media-breakpoint-up(lg) {
      &:nth-of-type(3n + 1) {
        clear: both;
      }
    }
  }

  .list-c-tooltip {
    display: none;
  }

  .product-preview-item {
    margin-bottom: 30px;
  }

  &.list-view {
    .list-c-tooltip {
      display: block;
      position: absolute;
      left: 100%;
      width: 200px;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 20px;
      font-size: 1rem;
    }

    > div {
      width: 100%;
    }

    .product-preview-item {
      position: relative;
      border-bottom: 0;
      padding-left: 300px;

      &:before {
        display: none;
      }

      &:hover {
        .text-block:before {
          width: 100%;
        }
      }

      .image-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 270px;
      }

      .text-block {
        padding: 0;
        text-align: left;
        min-height: 360px;
        padding-bottom: 90px;
        position: relative;
        border-bottom: 1px solid $border-c;

        &:before {
          content: "";
          position: absolute;
          bottom: -1px;
          left: 50%;
          @include translate(-50%, 0);
          width: 0;
          height: 1px;
          background-color: $color2-c;
          @include transition-all(0.4s);
        }

        h3 {
          text-transform: uppercase;
          font-size: 1.25rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-weight: 700;
          margin-bottom: 5px;
          a {
          }
        }

        .description {
          display: block;
        }
      }

      .options-block {
        text-align: left;
        color: $gray;
        background-color: transparent;
        position: absolute;
        width: 100%;
        opacity: 1;
        bottom: 20px;

        ul {
          background: transparent;

          > li {
            height: 50px;
            width: auto;
            display: inline-block;
            position: relative;
            margin-right: 10px;
            border: 1px solid $gray-c;

            &:nth-of-type(1) .c-tooltip {
              display: none !important;
            }

            &:nth-of-type(2) {
              display: none;
            }

            a {
              display: block;
              line-height: 28px;
              padding: 10px 15px;
              color: #888;
              font-size: 1.25rem;
              .only-list-view {
                font-size: 0.875rem;
                display: inline-block;
              }

              &:hover {
                background-color: $color2-c;
                border-color: $color2-c;
                color: $white-c;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-only(lg) {
      .product-preview-item {
        padding-left: 240px;

        .image-block {
          width: 210px;
        }

        .text-block {
          min-height: 280px;
        }
      }
    }

    @include media-breakpoint-between(sm, md) {
      .product-preview-item {
        padding-left: 180px;

        .image-block {
          width: 150px;
        }

        .text-block {
          min-height: 200px;
        }
      }
    }

    @include media-breakpoint-only(xs) {
      .product-preview-item {
        padding-left: 0;

        .image-block {
          position: relative;
          display: block;
          width: 210px;
          margin: 0 auto;
        }

        .text-block {
          min-height: 1px;
          margin-top: 1rem;
          padding-bottom: 10px;
        }

        .options-block {
          position: relative;
          bottom: 0;
        }
      }
    }
  }
}
.product-brend-title {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  h1 {
    font-weight: 700;
    position: relative;
    padding: 0 40px 10px 0;
    line-height: 30px;
    font-size: 1.75rem;
    text-transform: lowercase;
    margin: 0;
    color: $black-c;
    &:first-letter {
      text-transform: uppercase;
    }
    .plus-minus {
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 50%;
      width: 30px;
      height: 30px;
      display: inline-block;
      padding: 0;
      margin-top: -22px;
      z-index: 1;
      &:before {
        content: "";
        position: absolute;
        width: 22px;
        height: 4px;
        right: 0;
        @include center(y);
        background-color: $black-c;
      }
      &:after {
        content: "";
        position: absolute;
        width: 22px;
        height: 4px;
        right: 0;
        @include center(y);
        @include transform(rotate(90deg));
        background-color: $black-c;
        margin-top: -2px;
        @include transition-all(0.4s);
      }
      &.trigger-active {
        &:after {
          @include transform(rotate(0deg));
          @include transition-all(0.4s);
        }
      }
    }
    .brend-logo {
      max-height: 90px;
      margin-right: 20px;
    }
  }
  .checkboxes-holder {
    padding: 10px 0 15px;
    .img-text {
      display: inline-block;
    }
  }
  .img-text {
    .image-holder2 {
      float: left;
      img {
        width: 90px;
        max-height: 90px;
        margin-right: 20px;
      }
    }
    a {
      color: $red-c;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    padding-top: 5px;
    padding-left: 30px;

    li {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 8px;
        left: -15px;
        width: 6px;
        height: 6px;
        background-color: $gray-c;
        border-radius: 50%;
      }
    }
  }

  ol {
    padding-top: 5px;
    padding-left: 30px;

    li {
      position: relative;
      margin-bottom: 5px;
    }
  }
  p {
    margin: 0;
  }
}
