.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  background: $white-c;
  color: $black-c;
  border-left: 1px solid #999;
  .homepage-icon {
    display: none;
  }
}
.sidr .sidr-inner {
  padding: 0;
}
.sidr .sidr-inner > p {
  margin-left: 15px;
  margin-right: 15px;
}
.sidr.right {
  left: auto;
  right: -260px;
}
.sidr.left {
  left: -260px;
  right: auto;
}

.sidr ul {
  display: block;
  padding: 0;
}
.sidr ul li {
  display: block;
  margin: 0;
  line-height: 38px;
  border-bottom: 1px solid #999;
}
.sidr ul li a {
  padding-right: 30px!important;

  img{
    max-width: 30px;
    max-height: 30px;
    margin-right: 10px;
    margin-top: -4px;
  }
}

.sidr ul li:hover > a,
.sidr ul li:hover > span, .sidr ul li.active > a,
.sidr ul li.active > span, .sidr ul li.sidr-class-active > a,
.sidr ul li.sidr-class-active > span {
  color: $color1-c;
}
.sidr ul li a,
.sidr ul li span {
  padding: 0 10px;
  display: block;
  text-decoration: none;
  color: $black-c;
}
.sidr ul > li > ul > li > a,
.sidr ul ul li span {
  padding-left: 20px;
}
.sidr ul > li > ul > li > ul > li > a,
 .sidr ul ul ul li span {
   padding-left: 30px;
 }

.sidr ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 40px;
}
.sidr ul li ul {
  margin: 0;
  border-top: 1px solid #1a1a1a;
  border-bottom: 0;
  background-color: #eeeeee;
}
.sidr ul li ul li ul{
  background-color: #c6c4c4;
}
.sidr ul li ul li ul li ul{
  background-color: #a8a8a8;
}
.sidr ul ul > li:last-of-type {
  border-bottom: 0;
}
.sidr ul ul li:hover > a,
.sidr ul ul li:hover > span, .sidr ul li ul li.active > a,
.sidr ul ul li.active > span, .sidr ul li ul li.sidr-class-active > a,
.sidr ul ul li.sidr-class-active > span {
  color: $color1-c;
}

.sidr ul i {
  display: none;
}

.sidr ul li {
  position: relative;
  .submenu-trigger {
    cursor: pointer;
    position: absolute;
    right: 0; top: 0;
    width: 34px;
    height: 38px;
    background-color: $color1-c;
    display: inline-block;
    padding: 0;
    //    border-bottom: 1px solid $white-c;
    border-right: 1px solid $color1-c;
    z-index: 1;
    &:before {
      content: '';
      position: absolute;
      width: 16px; height: 2px;
      right: 7px;
      @include center(y);
      background-color: $white-c;
    }
    &:after {
      content: '';
      position: absolute;
      width: 16px; height: 2px;
      right: 7px;
      @include center(y);
      @include transform(rotate(90deg));
      background-color: $white-c;
      margin-top: -1px;
      @include transition-all(0.4s);
    }
    &.open-submenu {
      &:after {
        @include transform(rotate(0deg));
        @include transition-all(0.4s);
      }
    }
  }
}

.sidr-inner ul li > div,
.sidr-inner ul li > ul {
  display: none;
}