.c-slider {
  position: relative;
}

.c-slider-arr {
  position: absolute;
  display: inline-block;
  text-align: center;
  top: 50%;
  cursor: pointer;
  z-index: 1;
  @include translate(0, -50%);
}

//////////////////////////////
//HOMEPAGE SLIDER
//////////////////////////////
.homepage-slider {
  position: relative;
  display: none;
  &.slick-initialized{
    display: block;
  }
  // background-image: none !important;

  .cover-bg {
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.first-slide {
      video {
        display: block;
      }

      background-image: none !important;
    }
    img{
      width: 100%;
    }
  }

  // video {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }

  .c-slider-arr {
    color: $color2-c;
    border-color: $color2-c;
    line-height: 30px;
    width: 30px;
    font-size: 30px;
    &.prev-slide {
      left: 40px;
      border-right: 4px solid;
    }
    &.next-slide {
      right: 40px;
      border-left: 4px solid;
    }
    &:hover {
      color: $color1-c !important;
      border-color: $color1-c;
    }
  }

  .slick-slide {
    position: relative;
    overflow: hidden;
  }

  .cover-bg {
    background-position: inherit;
  }

  .background-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      transparent,
      transparent,
      $black-c
    );
    width: 100%;
    content: "";
  }

  .text-block-holder {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    > .container {
      position: relative;
      height: 100%;
    }
  }

  .text-block {
    position: absolute;
    text-align: center;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    // @include center(y);
    opacity: 0;
    @include transition(opacity 2s 0.5s);

    @media (max-width: 1024px) {
      white-space: normal;
    }
  }

  .text1 {
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    color: $white-c;

    position: relative;
    opacity: 0;
    z-index: -1;
    @include transition(opacity 2s 1s, left 2s);

    @media (max-width: 767.98px) {
      min-width: 320px;
    }
  }

  .text2 {
    font-size: 18px;
    font-weight: 300;

    line-height: 26px;
    color: $white-c;

    position: relative;
    opacity: 0;
    z-index: -1;
    @include transition(opacity 2s 1s, left 2s);

    @media (max-width: 767.98px) {
      min-width: 320px;
    }
  }

  .link-block {
    position: relative;
    text-align: center;
    margin-top: 10px;

    a {
      position: relative;
      top: 300%;
      opacity: 0;
      @include transition(opacity 2s 1.5s, top 2s);
    }
  }

  .slick-active {
    .text-block {
      opacity: 1;
    }

    .text1,
    .text2 {
      opacity: 1;
      left: 0;
      z-index: 1;
    }

    .link-block a {
      opacity: 1;
      top: 0;
      z-index: 1;
    }
  }
}

.homepage-slider {

  position: relative;

  ul.slick-dots {
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(-50%);
    bottom: 20px;
    left: 50%;

    li {
      > button {
        background-color: $gray-c;
        width: 20px;
        height: 4px;
        border: 0;
        transition: background-color 0.8s ease-in-out, width 0.8s ease-in-out;
      }
      &.slick-active {
        > button {
          background-color: $red-c;
          width: 40px;
        }
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  .homepage-slider {
    .c-slider-arr {
      display: none !important;
    }

    .link-block a {
      line-height: 15px;
      padding: 3px 10px;
      font-size: 10px;
    }
    .text-block{
      gap: 0;
    }
    .slick-active {
      .text1{
        font-size: 18px;
        line-height: 1.5;
      }
      .text2{
        font-size: 16px;
      }
    }
  }
}

//////////////////////////////
//PRODUCT TABS SLIDER
//////////////////////////////
.product-tabs {
  padding: 100px 0px;

  .c-slider-arr {
    top: 50%;
    // transform: translateY(-50%);
    color: $color2-c;
    border-color: $color2-c;
    line-height: 20px;
    width: 30px;
    font-size: 50px;
    &.prev-slide {
      left: -32px;
    }
    &.next-slide {
      right: -32px;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-down(xs) {
    .c-slider-arr {
      &.prev-slide {
        left: 0;
        transform: translate(-50%, -50%);
      }
      &.next-slide {
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }
}

.tabs-slider {
  display: none;
  &.slick-initialized{
    display: block;
  }
  .c-slide-pos {
    padding: 0 5px;
  }
}

.tab-pane {
  visibility: hidden;
  opacity: 0;
  height: 0;
  @include transition-all(0.4s);
  &.active {
    visibility: visible;
    opacity: 1;
    height: auto;
  }
}

//////////////////////////////
//PRODUCTS SLIDER
//////////////////////////////
.products-slider-section {
  padding-bottom: 100px;
  //border-bottom: 1px solid $border-c;
  @include media-breakpoint-down(md) {
    padding: 20px 0;
  }
}

.products-slider {
  margin-top: 50px;
  display: none;
  &.slick-initialized{
    display: block;
  }
  .c-slide-pos {
    padding: 0 5px;
    @include media-breakpoint-down(sm) {
      padding: 0 10px;
    }
  }
  .c-slider-arr {
    top: 50%;
    // transform: translateY(-50%);
    color: $color2-c;
    border-color: $color2-c;
    line-height: 20px;
    width: 30px;
    font-size: 50px;
    &.prev-slide {
      left: -32px;
    }
    &.next-slide {
      right: -32px;
    }
    &:hover {
      color: $color1-c !important;
    }
  }
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
  @include media-breakpoint-down(xs) {
    .c-slider-arr {
      &.prev-slide {
        left: 0;
        transform: translate(-50%, -50%);
      }
      &.next-slide {
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }
}


//////////////////////////////
//BRANDS SLIDER
//////////////////////////////
.brands-section {
  position: relative;
  padding-bottom: 100px;

  .brands-slider {
    display: none;
    &.slick-initialized{
      display: block;
    }
    .c-slide-pos {
      border: 1px solid transparent;
      transition: border 0.8s ease-in-out;

      &:hover {
        border: 1px solid $color1-c;
      }

      img {
        width: 100%;
        height: 172px;
      }
    }

    /* Postavite border-right na 0 za prvi child */
    > ul.slick-dots {
      display: flex;
      gap: 2px;
      justify-content: center;
      align-items: center;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 100%;
      margin-top: 20px;
      flex-wrap: wrap;

      li {
        > button {
          background-color: $gray-c;
          width: 20px;
          height: 4px;
          border: 0;
          transition: background-color 0.8s ease-in-out, width 0.8s ease-in-out;
        }
        &.slick-active {
          > button {
            background-color: $red-c;
            width: 40px;
          }
        }
      }
    }
  }
}

//////////////////////////////
//BEST SELLERS SLIDER (ON PRODUCT PAGE)
//AND FEATURED PRODUCTS (ON PRODUCT LIST PAGE)
//////////////////////////////
.best-sellers,
.featured-products,
.recommended-products {
  .block-title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
  }
}

.best-sellers-slider,
.featured-products-slider,
.recommended-products-slider {
  .c-slider-arr {
    top: -35px;
    color: $black-c;
    border-color: $black-c;
    line-height: 20px;
    width: 30px;
    font-size: 20px;
    &.prev-slide {
      right: 30px;
      &:before {
        content: "";
        position: absolute;
        right: -1px;
        top: 0;
        height: 20px;
        width: 2px;
        background-color: $black-c;
      }
    }
    &.next-slide {
      right: 0;
    }
    &:hover {
      color: $color1-c !important;
    }
  }

  .slick-slide {
    position: relative;
    padding-left: 90px;
    min-height: 110px;
    padding-bottom: 10px;
  }

  .image-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 75px;
    height: 100px;
  }

  .product-name {
    font-size: 0.875rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0.5rem;

    a:hover {
      color: $color1-c;
    }
  }

  .price-block {
    font-weight: 700;

    .price {
      display: block;
      color: $color1-c;
      font-size: 0.875rem;
      // margin: 0 0;
    }

    .old-price {
      display: inline-block;
      padding: 0 5px;
      position: relative;
      font-size: 0.75rem;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: $black-c;
      }
    }
  }
  .large-button {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $white-c;
    background-color: $color1-c;
    text-align: center;
    line-height: 30px;
    display: block;
    width: 100%;
    height: 30px;
    border: 1px solid $color1-c;
    cursor: pointer;
    font-weight: 700;
    margin-top: 10px;
    @include transition-all(0.4s);
    &:hover {
      background-color: $black-c;
      color: $white-c;
      border: 1px solid $black-c;
    }
  }
}

//////////////////////////////
//RELATED BLOG SLIDER
//////////////////////////////
.related-blog-slider {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .slick-list {
    display: flex;
    // margin: 0 -10px;
    > .slick-track {
      display: flex;
      justify-content: center;
      gap: 10px;
      flex: 1;
    }
  }
  > ul.slick-dots {
    display: flex;
    gap: 2px;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 100%;
    margin-top: 20px;

    li {
      > button {
        background-color: $gray-c;
        width: 20px;
        height: 4px;
        border: 0;
        transition: background-color 0.8s ease-in-out, width 0.8s ease-in-out;
      }
      &.slick-active {
        > button {
          background-color: $red-c;
          width: 40px;
        }
      }
    }
  }

  //   .c-slider-arr {
  //     top: -67px;
  //     left: 95%;
  //     color: $white-c !important;
  //     width: 30px;
  //     height: 40px;
  //     line-height: 37px;
  //     font-size: 20px;
  //     @include translate(0, 0);
  //     &.prev-slide {
  //       margin-left: -30px;
  //       &:before {
  //         content: "";
  //         position: absolute;
  //         right: -1px;
  //         top: 9px;
  //         height: 20px;
  //         width: 2px;
  //         background-color: $white-c;
  //       }
  //     }
  //     &.next-slide {
  //       right: 0;
  //     }
  //     &:hover {
  //       color: $color1-c !important;
  //     }
  //     @include media-breakpoint-down(xs) {
  //       left: 90%;
  //     }
  //   }
}

.wrapper-banner-home,
.wrapper-blog-preview{
  display: none;
  &.slick-initialized{
    display: block;
  }
}