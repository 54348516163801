.c-banner {
  position: relative;
  margin: 0 auto;
  max-width: 1000px;
  list-style: none;
}

/* Common style */
.c-banner figure {
  position: relative;
  cursor: pointer;
  margin: 0 0 11px 0;

  @media (max-width: 575.98px) {
    overflow: hidden;
    margin: 0;
  }

  // @media (min-width: 576px) {
  //   margin: 0 0 11px 0;
  // }
}

.c-banner figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  // opacity: 0.8;
  -webkit-backface-visibility: hidden;

  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 100%;
  }
}

.c-banner figure figcaption {
  padding: 15px;
  color: $white-c;
  // text-transform: uppercase;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.c-banner figure figcaption::before,
.c-banner figure figcaption::after {
  pointer-events: none;
}

.c-banner figure figcaption,
.c-banner figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.c-banner figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
  -webkit-backface-visibility: hidden;
}

.c-banner figure h2 {
  word-spacing: -0.15rem;
  font-weight: 400;
}

.c-banner figure h2 span {
  font-weight: 700;
}

.c-banner figure h2,
.c-banner figure p {
  margin: 0;
}

.c-banner figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

/* Individual effects */

/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
  // background: $black-c;
  // position: relative;
  // z-index: 2;

  // img {
  //   // opacity: 0.7;
  //   // -webkit-transition: opacity 0.35s;
  //   // transition: opacity 0.35s;

  // }
  .bg {
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(27, 25, 24, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 1;
    transition: opacity 0.7s ease-in-out;
  }

  figcaption {
    display: flex;
    flex-direction: column-reverse;
    justify-content: end;
    align-items: baseline;
    z-index: 10;
  }

  figcaption::before,
  figcaption::after {
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    content: "";
    opacity: 0;
    -webkit-transition: opacity 0.8s, -webkit-transform 0.8s;
    transition: opacity 0.8s, transform 0.8s;
  }

  h2 {
    font-size: 24px;
    -webkit-transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    font-weight: 500;
    line-height: 29px;
  }

  p {
    padding: 10px 0px;
    opacity: 0;
    -webkit-transition: opacity 0.8s, -webkit-transform 0.8s;
    transition: opacity 0.8s, transform 0.8s;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
    font-weight: 300;
    line-height: 26px;
    font-size: 18px;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -11px;
    width: 100%;
    height: 4px;
    background-color: $red-c;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.9s ease-in-out;
    z-index: 10;
  }

  &:hover::after {
    transform: scaleX(1);
    -webkit-transform: scaleX(1);
  }

  &:hover {
    .bg {
      background: rgba(27, 25, 24, 0.5);
    }
    figcaption {
      flex-direction: column;
      justify-content: flex-end;
    }

    figcaption::before,
    figcaption::after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    h2,
    p {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}
