.text-editor {
  line-height: 1.6;

  h1 {
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-c;
  }

  h2 {
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $border-c;
  }

  h3 {
    font-size: 0.875rem;
    font-weight: 700;
  }

  p {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.6875rem;
    margin-bottom: 1.25rem;
    color: #707070;
  }

  a {
    text-decoration: underline;
    color: $text-c;
    &:hover {
      text-decoration: underline;
      color: $color1-c;
    }
  }

  ul {
    padding-top: 5px;
    padding-left: 20px;

    li {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: -20px;
        width: 7px;
        height: 7px;
        background-color: $color2-c;
      }
    }
  }

  ol {
    padding-top: 5px;
    padding-left: 30px;

    li {
      position: relative;
      margin-bottom: 5px;
    }
  }

  blockquote {
    padding: 1rem;
    border-left: 5px solid $color1-c;
    background-color: $gray-c;
    margin-bottom: 0;
    font-weight: 600;
  }
}
