.find-us-on-map {
  .map-trigger-holder {
    background-color: $red-c;
    padding: 11px 20px;
  }

  .map-trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white-c;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    padding: 11px 0px;

    @media (max-width: 575.98px) {
      font-size: 16px;
    }

    & i {
      font-weight: 900;
    }
  }

  .map {
    height: 400px;
    position: relative;
    overflow: hidden;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }
}
