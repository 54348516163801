/*************** SCROLLBAR BASE CSS ***************/

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
}

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}
.scroll-element, .scroll-element div {
  box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default;
}

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999;
}
.scroll-textarea > .scroll-content {
  overflow: hidden !important;
}
.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important;
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}




/*************** SIMPLE INNER SCROLLBAR ***************/

.scroll-holder > .scroll-element,
.scroll-holder > .scroll-element div
{
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scroll-holder > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scroll-holder > .scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%;
}

.scroll-holder > .scroll-element.scroll-y {
  height: 100%;
  right: 5px;
  top: 0;
  width: 10px;
}

.scroll-holder > .scroll-element .scroll-element_outer {
  overflow: hidden;
}

.scroll-holder > .scroll-element .scroll-element_outer,
.scroll-holder > .scroll-element .scroll-element_track,
.scroll-holder > .scroll-element .scroll-bar {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.scroll-holder > .scroll-element .scroll-element_track,
.scroll-holder > .scroll-element .scroll-bar {
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=100);
  opacity: 1;
}
.scroll-holder > .scroll-element .scroll-bar{opacity: 1;}

.scroll-holder > .scroll-element .scroll-element_track { background-color: rgba($color1-c, 0.5); width: 6px; margin-left: 2px;}
.scroll-holder > .scroll-element .scroll-bar { background: $color1-c; }
.scroll-holder > .scroll-element:hover .scroll-bar { background-color: $color1-c; }
.scroll-holder > .scroll-element.scroll-draggable .scroll-bar { background-color: $color1-c; }


/* update scrollbar offset if both scrolls are visible */

.scroll-holder > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track { left: -12px; }
.scroll-holder > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track { top: -12px; }


.scroll-holder > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -12px; }
.scroll-holder > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -12px; }

.scroll-wrapper.scroll-holder{
  max-height: 270px;
  margin-right: -20px;
}