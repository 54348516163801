.price-range-holder {
  padding: 0 6px;
  margin-top: 40px;

  .slider-range {
    background: $gray-c;
    border: none;
    height: 5px;
    margin-bottom: 10px;
    width: 100% !important;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    z-index: 0;

    & .ui-slider-range {
      background: $gray-c;
      border: 0 none;
      height: 7px;
      border-radius: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
    }

    // & .ui-state-focus,
    & .ui-state-hover,
    & .ui-state-active,
    & .ui-slider-handle {
      width: 18px;
      height: 18px;
      border: 3px solid $red-c;
      background: $red-c !important;
      border-radius: 50%;
      cursor: pointer;
      margin-left: -6px;
      top: -5px;
    }
  }

  .slider-input {
    border: 0;
    padding: 5px 0;
    color: $black-c;
    line-height: 22px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }

  .ui-slider .ui-slider-handle {
    z-index: 1;
  }
}
