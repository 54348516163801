footer {
  background-color: $color2-c;
  color: $white-c;

  .top {
    padding: 40px 0 30px 0;
  }

  /* Footer Intesa */
  .middle-block {
    background: #fff;
    padding: 10px 0;
  }
  // .middle-block .right {
  //   text-align: right;
  // }
  .middle-block img {
    max-height: 30px;
    width: auto !important;
    margin: 0 7px;
    @media (max-width: 575.98px) {
      max-height: 13px;
    }
  }
  .middle-block {
    .wrapper-middle-block {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      @media (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 7px;
      }
    }
  }
  // .middle-block .col-md-5 {
  //   width: 37%;
  // }
  // .middle-block .col-md-2 {
  //   width: 19%;
  // }
  // .middle-block .col-md-5:last-child {
  //   width: 43%;
  // }

  // @media (max-width: 992px) {
  //   .middle-block .col-md-5,
  //   .middle-block .col-md-2 {
  //     width: 100% !important;
  //     text-align: center !important;
  //     padding: 10px 0;
  //   }
  //   .middle-block .right {
  //     text-align: center;
  //   }
  //   .middle-block .foo-links {
  //     margin-bottom: 15px;
  //   }
  //   .middle-block .col-md-12:last-child .foo-links {
  //     margin-bottom: 0;
  //   }
  // }

  .read-more {
    display: flex;
    a {
      display: flex;
      align-items: baseline;
      gap: 6px;
      line-height: 22px;
      color: $white-c !important;
      font-size: 18px;
    }
    span {
      font-weight: 600;
    }

    // float: right;
    // @extend .clearfix;
    // text-decoration: underline;
    // &:hover {
    //   color: $color1-c;
    //   text-decoration: underline;
    // }
  }
  .wrapper-social_networks {
    display: flex;

    @media (max-width: 991.98px) {
      padding-bottom: 25px;
    }

    @media (max-width: 575.98px) {
      padding: 25px 0px;
    }

    .border-icons {
      .link-icons{
        padding: 8px;
        // border: 1px solid $border-c;
        border: 1px solid #707070;
        opacity: 0.7;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        // .link-icons img {
        //   height: 25px;
        // }
        transition: opacity 0.6s ease-in-out, border 0.6s ease-in-out;
  
        &:hover {
          border: 1px solid $border-c;
          opacity: 1;
        }
      }
    }
  }

  img {
    //margin-bottom: 15px;
  }

  .foo-block {
    // margin-top: 20px;
    // margin-bottom: 20px;
    // margin: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: 575.98px) {
      gap: 0px;
    }
    .foo-title {
      font-size: 18px;
      text-transform: capitalize;
      font-weight: 700;
      @media (max-width: 575.98px) {
        padding: 20px 0px;
      }
    }
    .foo-text {
      font-weight: 300;
      line-height: 29px;
      font-size: 18px;
    }
    &.foo-contact{
      a{
        color: $white-c;
      }
    }
    @include media-breakpoint-down(sm) {
      .foo-text {
        max-width: 320px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      li {
        display: block;
        margin-bottom: 5px;
      }
      a {
        display: inline-block;
        // position: relative;
        // padding-left: 15px;
        // font-size: 0.75rem;
        font-weight: 300;
        line-height: 29px;
        color: $white-c;
        line-height: 29px;
        font-size: 18px;
        // &:before {
        //   content: "\f105";
        //   font-family: FontAwesome;
        //   color: $color1-c;
        //   font-size: 14px;
        //   position: absolute;
        //   left: 0;
        //   top: 50%;
        //   @include translate(0, -50%);
        //   @include transition-all(0.2s);
        // }
        // &:hover {
        //   &:before {
        //     left: 3px;
        //   }
        // }
      }
    }
    table {
      margin-top: -10px;
    }
    tbody > tr {
      td {
        padding-left: 25px;
        position: relative;
        font-size: 18px;
        line-height: 29px;
        padding-top: 10px;
        padding-bottom: 10px;
        &:before {
          position: absolute;
          left: 1px;
          top: 50%;
          @include translate(0, -50%);
          font-size: 1rem;
          font-family: FontAwesome;
        }
      }

      &:nth-of-type(1) td:before {
        content: "\f041";
      }
      &:nth-of-type(2) td:before {
        content: "\f095";
      }
      &:nth-of-type(3) td:before {
        content: "\f0e0";
      }
      &:nth-of-type(4) td:before {
        content: "\f017";
      }
    }
  }

  .bottom {
    // border-top: 5px solid $border-c;
    padding: 12px 0px;
    background-color: $color2-c;
    min-height: 64px;

    .wrapper-all_rights {
      padding-top: 10px;
    }
  }

  .copyright {
    display: block;
    color: $gray-c;
    font-size: 0.75rem;
    font-weight: 300;
    .fa {
      color: $color1-c;
    }
    a {
      color: $white-c;
      font-weight: 400;
    }
  }
}
