
//////////////////////////////////////////////////////////
//MODAL
//////////////////////////////////////////////////////////
.ma-modal {
  position: fixed;
  left: 0; top: 0;
  height: 100%; width: 100%;
  content: '';
  background-color: rgba(0, 0, 0, 0.9);
  z-index: -10;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  @include transition-all(0.4s);

  .ma-modal-content {
    position: absolute;
    left: 50%; top: 50%;
    @include translate(-50%, -50%);
    background-color: $white-c;
    height: 90%;
    overflow-x: hidden;
    width: 90%;
    max-width: 980px;
    padding: 140px 20px 20px 20px;
  }

  .ma-close {
    top: 20px!important; right: 20px;
    display: inline-block!important;
    position: absolute;
    width: 40px; height: 40px;
    color: $color1-c;
    font-size: 1.125rem;
    text-align: center;
    border: 1px solid $gray-c;
    line-height: 38px;
  }

  .ma-modal-title {
    position: absolute;
    left: 20px;
    top: 80px;
    font-size: 2.5rem;
  }
}

.ma-modal-open .ma-modal {
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}


/////////////////////////////////////////////////////////////////////////////
//RESPONSIVE
/////////////////////////////////////////////////////////////////////////////
@media(max-width: 1199px){
  .ma-modal {
    .ma-modal-content {
      padding: 100px 20px 20px 20px;
      height: auto;
      max-height: 90%;
    }
    .ma-modal-title {
      left: 20px;
      top: 60px;
      font-size: 1.5rem;
    }
  }
}