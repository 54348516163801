.product-preview-item {
  position: relative;
  border-bottom: 1px solid $gray-c;
  transition: border-bottom 0.8s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .list-c-tooltip {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 50%;
    @include translate(-50%, 0);
    width: 0;
    height: 1px;
    background-color: $color2-c;
    @include transition-all(0.4s);
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: $red-c;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.8s ease-in-out;
  }

  &:hover {
    &:hover::after {
      transform: scaleX(1);
    }

    .image-block {
      border: 1px solid $red-c !important;
    }

    &:before {
      width: 100%;
    }
    // .options-block {
    //   opacity: 1;
    //   bottom: 0;
    // }
    .badges-block .bottom {
      bottom: 60px;
    }
    .images-holder.two-images {
      @include translate(-50%, 0);
    }

    .button-wrapper {
      a {
        color: $red-c;

        .arrow-right-black {
          display: none;
        }
        .arrow-right-red {
          display: block;
        }
      }
    }

    .options-block {
      display: block;

      width: 40px;
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .image-block {
    position: relative;
    overflow: hidden;
    border: 1px solid $gray-c !important;
    transition: border 0.8s ease-in-out;
  }

  .images-holder.two-images {
    width: 200%;
    position: relative;
    @include transition-all(0.4s);

    > div {
      width: 50%;
      float: left;
    }
  }

  .text-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 10px;

    // text-align: center;

    .p-title {
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
      // margin-bottom: 5px;
      height: 25px;
      position: relative;
      a {
        display: inline-block;
        width: 100%;
        position: absolute;
        @include center(xy);
      }
    }

    .description {
      display: none;
      padding: 1.5rem 0;
    }

    .price-block {
      font-weight: 700;
      position: relative;
      height: 25px;

      .price-position-helper {
        position: absolute;
        width: 100%;
        top: 0;
      }

      .price {
        display: inline-block;
        // margin: 0 5px;

        color: $color1-c;
        font-size: 1rem;
        font-weight: 700;
        line-height: 26px;
      }
      .old-price {
        display: inline-block;
        padding: 0 5px;
        position: relative;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        // color: #707070;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          height: 1px;
          background-color: #707070;
        }
      }
    }
  }

  .badges-block {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    > span {
      position: absolute;
      top: 10px;
      display: inline-block;
      width: 40px;
      height: 40px;
      // border-radius: 50%;
      font-size: 0.75rem;
      color: $white-c;
      font-weight: 700;
      line-height: 40px;
      text-align: center;
    }
    .new {
      background-color: $color2-c;
    }
    .action {
      background-color: $color1-c;
    }
    .recommended {
      border: 2px solid $color1-c;
      color: $color1-c;
      background-color: $white-c;
    }
    .left {
      left: 10px;
    }
    .right {
      right: 10px;
    }
    .bottom {
      right: 10px;
      top: auto;
      bottom: 10px;
      font-size: 1rem;
      @include transition-all(0.4s);
    }
  }

  .options-block {
    display: none;
    text-align: center;
    color: $white-c;
    background-color: $black-c;
    position: absolute;
    // width: 100%;
    transform: translate(-10px, -10px);
    opacity: 1;
    bottom: 0px;
    right: 0px;

    @include transition-all(0.4s);
    ul {
      display: inline-block;
      li {
        height: 50px;
        width: 50px;
        display: inline-block;
        position: relative;
        a {
          display: block;
          line-height: 30px;
          padding: 10px 0;
          color: $white-c;
          font-size: 1.25rem;
          .only-list-view {
            display: none;
          }
        }

        .c-tooltip {
          position: absolute;
          opacity: 1;
          visibility: hidden;
          z-index: -1;
          left: 50%;
          bottom: 90%;
          @include translate(-50%, 0);
          padding: 5px;
          color: $white-c;
          background-color: $color1-c;
          font-size: 10px;
          display: inline-block;
          white-space: nowrap;
          @include transition-all(0.4s);
          &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 8px 0 8px;
            border-color: $color1-c transparent transparent transparent;
            top: 100%;
            left: 50%;
            margin-left: -8px;
          }
        }
        &:hover {
          .c-tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 1;
            bottom: 100%;
          }
        }
      }
    }
  }

  .send-request{
    color: $red-c;
    border: 1px solid $red-c; 
    padding: 12px;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 22px;
    margin-top: 29px;
  }
  .wrapper-add_cart {
    background: $red-c;
    color: $white-c;
    padding: 12px;
    text-align: center;
    cursor: pointer;
    border: 1px solid $red-c;
    text-transform: uppercase;

    font-weight: 600;
    line-height: 22px;
  }

  .button-wrapper a {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
    justify-content: center;
    color: $black-c;
    font-weight: 600;
    line-height: 22px;

    .arrow-right-red {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .product-preview-item {
    &:before {
      width: 100%;
    }
    .image-block {
      padding-bottom: 50px;
    }

    .options-block {
      opacity: 1;
      bottom: 0;
      ul {
        display: inline-block;
        li:nth-of-type(2) {
          display: none;
        }
      }
    }
    .badges-block .bottom {
      bottom: 60px;
    }
  }
}

.touch {
  .product-preview-item {
    &:before {
      width: 100%;
    }
    .image-block {
      padding-bottom: 50px;
    }
    .options-block {
      opacity: 1;
      bottom: 0;
    }
    .badges-block .bottom {
      bottom: 60px;
    }
  }
}
