//BLOG PREVIEW (HOMEPAGE
.blog-preview-section {
  padding: 100px 0px;
  .wrapper-blog-preview {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
    flex-direction: column;

    .slick-list {
      display: flex;
      > .slick-track {
        display: flex;
        justify-content: center;
        gap: 10px;
        flex: 1;
      }
    }

    .item-blog-preview {
      // display: flex;
      // flex-direction: column;
      border: 1px solid $border-c;
      cursor: pointer;
      // flex: 1;
      transition: border 0.8s ease-in-out;

      &:hover {
        border: 1px solid $color1-c;

        .wrapper-preview-text {
          .title-blog-preview {
            > h3 {
              color: $color1-c;
            }
          }
        }
      }

      > a {
        .img-wrapper-blog {
          // background-position: center;
          // background-repeat: no-repeat;
          // background-size: cover;
          height: 200px;
          width: 100%;
        }
      }

      .wrapper-preview-text {
        display: flex;
        flex-direction: column;
        padding: 20px;
        flex: 1;

        .title-blog-preview {
          > h3 {
            font-weight: 700;
            font-size: 18px;
            line-height: 27px;
            color: $black-c;
            transition: color 0.8s ease-in-out;
            min-height: 55px;
          }
        }

        .p-blog-preview {
          > p {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #707070;
            margin-bottom: 0px !important;
          }
        }
      }
    }

    > ul.slick-dots {
      display: flex;
      gap: 2px;
      justify-content: center;
      align-items: center;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 100%;
      margin-top: 20px;

      li {
        > button {
          background-color: $gray-c;
          width: 20px;
          height: 4px;
          border: 0;
          transition: background-color 0.8s ease-in-out, width 0.8s ease-in-out;
        }
        &.slick-active {
          > button {
            background-color: $red-c;
            width: 40px;
          }
        }
      }
    }
  }

  // .image-wrapper {
  //   overflow: hidden;
  //   img {
  //     position: absolute;
  //   }
  // }

  // .about {
  //   width: 115px;
  //   border: 2px solid $color1-c;
  //   background-color: $white-c;
  //   font-weight: 700;
  //   text-align: center;
  //   padding: 10px 0;
  //   text-transform: uppercase;
  //   position: absolute;
  //   left: 25px;
  //   bottom: -25px;
  //   @include transition-all(0.4s);
  //   .date {
  //     display: block;
  //     font-size: 12px;
  //     position: relative;
  //     padding-bottom: 10px;
  //     margin-bottom: 10px;
  //     &:before {
  //       content: "";
  //       position: absolute;
  //       left: 50%;
  //       bottom: 0;
  //       @include translate(-50%, 0);
  //       width: 30px;
  //       height: 1px;
  //       background-color: $border-c;
  //     }
  //   }
  //   .comments-qty {
  //     display: block;
  //     font-size: 10px;
  //   }
  // }
  // .image-block:hover {
  //   .about {
  //     background-color: $color1-c;
  //     color: $white-c;
  //     .date {
  //       &:before {
  //         background-color: $white-c;
  //       }
  //     }
  //   }
  // }

  // .text-block {
  //   h3 {
  //     font-size: 1rem;
  //     font-weight: 700;
  //     text-transform: uppercase;
  //   }
  //   p {
  //     font-size: 0.75rem;
  //   }
  // }

  // @include media-breakpoint-down(md) {
  //   padding-top: 0;
  // }
  // @include media-breakpoint-only(xs) {
  //   // .blog-preview-item {
  //   //   margin-bottom: 30px;
  //   // }
  //   .image-block {
  //     margin-bottom: 35px;
  //   }
  // }

  // @include media-breakpoint-down(sm) {
  //   .about {
  //     padding: 5px 0;
  //     .date {
  //       padding-bottom: 5px;
  //       margin-bottom: 5px;
  //     }
  //   }
  // }
}

//BLOG LIST PAGE
.blog-list-page {
  .blog-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    column-gap: 10px;
    @media (max-width: 992px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    // display: inline-block;
    .col-lg-4:nth-of-type(3n + 1) {
      clear: both;
    }
    @include media-breakpoint-down(md) {
      .col-lg-4:nth-of-type(3n + 1) {
        clear: unset;
      }
      .col-md-6:nth-of-type(2n + 1) {
        clear: both;
      }
    }
  }

  .blog-post-preview {
    position: relative;
    margin-bottom: 30px;
    border: 1px solid $border-c;
    background-color: $white-c;

    transition: border 0.8s ease-in-out;

    &:hover {
      border: 1px solid $color1-c;
    }

    .image-block {
      .image-top {
        width: 100%;
      }
    }

    // @include media-breakpoint-down(md) {
    //   margin-bottom: 20px;
    // }

    // .image-block {
    //   position: relative;
    // }

    // .image-block:hover {
    //   .about {
    //     background-color: $color1-c;
    //     color: $white-c;
    //     .date {
    //       &:before {
    //         background-color: $white-c;
    //       }
    //     }
    //   }
    // }

    .author-categories {
      font-size: 0.75rem;

      span {
        display: inline-block;
      }

      .author .title {
        color: #bababa;
      }

      .divider {
        color: #bababa;
        padding: 0 5px;
      }
    }

    .text-block {
      // padding: 20px;
      display: flex;
      flex-direction: column;
      padding: 20px;

      .about {
        border-bottom: 1px solid $border-c;
        padding-bottom: 10px;
        //width: 115px;
        //border: 2px solid $color1-c;
        //background-color: $white-c;
        //font-weight: 700;
        //text-align: center;
        //padding: 10px 0;
        //text-transform: uppercase;
        //position: absolute;
        //left: 25px; bottom: -25px;
        @include transition-all(0.4s);
        //.date {
        //  display: block;
        //  font-size: 12px;
        //  position: relative;
        //  padding-bottom: 10px;
        //  margin-bottom: 10px;
        //  &:before {
        //    content: '';
        //    position: absolute;
        //    left: 50%; bottom: 0;
        //    @include translate(-50%, 0);
        //    width: 30px; height: 1px;
        //    background-color: $border-c;
        //  }
        //}
        //.comments-qty {
        //  display: block;
        //  font-size: 10px;
        //}
      }
      h3 {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 27px;
        // padding: 1rem 0;
        // margin-bottom: 1rem;

        color: $black-c;
        transition: color 0.8s ease-in-out;
        min-height: 55px;
      }
      p {
        font-size: 16px;
        // margin: 0;
        font-weight: 300;
        color: #707070;
      }
      .short-text {
        margin: 0;
        min-height: 88px;
        display: block;
      }
    }

    // @include media-breakpoint-down(md) {
    //   // .blog-preview-item {
    //   //   margin-bottom: 30px;
    //   // }
    //   .image-block {
    //     margin-bottom: 35px;
    //   }
    // }

    @include media-breakpoint-down(sm) {
      .about {
        padding: 5px 0;
        .date {
          padding-bottom: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

//BLOG LIST PAGE
.single-blog-page {
  .blog-post {
    position: relative;

    .image-block {
      position: relative;
      padding: 20px 0px;
    }

    .about {
      width: 115px;
      border: 2px solid $color1-c;
      background-color: $white-c;
      font-weight: 700;
      text-align: center;
      padding: 10px 0;
      text-transform: uppercase;
      position: absolute;
      left: 25px;
      bottom: -25px;
      @include transition-all(0.4s);
      .date {
        display: block;
        font-size: 12px;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          @include translate(-50%, 0);
          width: 30px;
          height: 1px;
          background-color: $border-c;
        }
      }
      .comments-qty {
        display: block;
        font-size: 10px;
      }
    }

    .author-categories {
      font-size: 0.75rem;
      padding: 1rem 0;

      span {
        display: inline-block;
      }

      .author .title {
        color: #bababa;
      }

      .divider {
        color: #bababa;
        padding: 0 5px;
      }
    }

    .text-block {
      h1 {
        font-size: 36px;
        font-weight: 700;
        line-height: 51px;
        text-transform: uppercase;
        padding-bottom: 20px;
      }

      .text-editor {
        p {
          font-size: 18px;
          font-weight: 300 !important;
          line-height: 1.5 !important;
        }
      }
    }
  }

  .social-links-holder {
    margin-top: 43px;
    border-top: 1px solid $gray-c;
    padding-top: 20px;

    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 575.98px) {
      flex-direction: column;
    }

    > span {
      color: #707070;
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
    }

    // .block-title {
    //   font-size: 0.75rem;
    //   font-weight: 700;
    //   margin-bottom: 1rem;
    //   display: block;
    //   text-transform: uppercase;
    // }

    .social-links {
      display: block;
      @extend .clearfix;

      li {
        display: inline-block;
        float: left;

        // &:not(:first-of-type) {
        //   a {
        //     border-left: 0;
        //   }
        // }
      }

      a {
        display: block;
        // line-height: 38px;
        width: 40px;
        border: 1px solid $border-c;
        color: #707070;
        text-align: center;
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        padding: 8px;
        transition: border 0.6s ease-in-out;

        &:hover {
          // border-color: $color1-c;
          // background-color: $black-c;
          // color: $white-c !important;
          border: 1px solid $red-c;
        }
      }
    }
  }

  .related-blog {
    margin-top: 100px;
    padding-bottom: 100px;

    // .category-title {
    //   background-color: $black-c;
    //   font-size: 1.25rem;
    //   padding: 17px 20px;
    //   margin-bottom: 20px;
    //   color: $white-c;
    //   letter-spacing: 2px;
    // }
    // .text-block {
    //   h3 {
    //     font-size: 24px;
    //     font-weight: 700;
    //     line-height: 29px;
    //     padding: 20px 0px;
    //     text-transform: uppercase;
    //   }
    //   p {
    //     font-size: 18px;
    //     // min-height: 75px;
    //     font-weight: 300;
    //     color: #707070;
    //     line-height: 27px;
    //   }
    // }

    .related-blog-slider {
      .blog-post-preview {
        border: 1px solid $gray-c;
        transition: border 0.8s ease-in-out;

        &:hover {
          border: 1px solid $color1-c;
        }

        .text-block {
          display: flex;
          flex-direction: column;
          padding: 20px;

          h3 {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 27px;
            color: $black-c;
            min-height: 55px;
          }

          p {
            font-size: 16px;
            font-weight: 300;
            color: #707070;
          }
        }
      }
    }
  }

  .comments-block {
    .block-title {
      text-transform: uppercase;
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  .recommended-products {
    h2 {
      padding: 12px 24px;
      color: $white-c;
      background-color: $color1-c;
      border: 1px solid transparent;
      text-align: center;
      text-transform: uppercase;
      transition: border 0.6s ease-in-out, color 0.6s ease-in-out,
        background-color 0.6s ease-in-out;
      cursor: pointer;
      font-weight: 500;
      line-height: 22px;
      font-size: 18px;
      margin-bottom: 20px;
      &:hover {
        color: $color1-c;
        border: 1px solid $color1-c;
        background-color: transparent;
      }
    }

    @include media-breakpoint-down(md) {
      margin-top: 40px;
    }

    .container-recommended-product {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .text-block {
        h4 {
          @media (max-width: 767.98px) {
            min-height: 70px;
          }
        }
      }
      .wrapper-add_cart {
        width: 100%;
      }

      @media (min-width: 576px) and (max-width: 767.98px) {
        .button-wrapper {
          padding-top: 10px;
        }
      }

      // SLIDER
      .slick-list {
        .slick-track {
          .slick-slide {
            margin: 0 5px;
            @media (max-width: 575.98px) {
              display: flex;
              justify-content: center;
              width: 100%;
            }
            .slick-list {
              margin: 0 -5px;
            }
          }
        }
      }

      .c-slider-arr {
        top: 50%;
        // transform: translateY(-50%);
        color: $color2-c;
        border-color: $color2-c;
        line-height: 20px;
        width: 30px;
        font-size: 50px;
        &.prev-slide {
          left: -32px;
          //&:before {
          //  content: '';
          //  position: absolute;
          //  right: -1px; top: 0;
          //  height: 20px; width: 2px;
          //  background-color: $color2-c;
          //}
        }
        &.next-slide {
          right: -32px;
        }
        &:hover {
          color: $color1-c !important;
        }
      }
      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
      @include media-breakpoint-down(xs) {
        .c-slider-arr {
          &.prev-slide {
            left: 0;
            transform: translate(-50%, -50%);
          }
          &.next-slide {
            right: 0;
            transform: translate(50%, -50%);
          }
        }
      }
    }

    // @media (min-width: 768px) {
    //   .scrollY {
    //     height: 100vh;
    //     overflow-y: scroll;
    //     padding-right: 5px;
    //     scroll-behavior: smooth;
    //     cursor: pointer;

    //     &::-webkit-scrollbar {
    //       width: 5px;
    //     }

    //     &::-webkit-scrollbar-thumb {
    //       background-color: $red-c;
    //       // background-image: -webkit-gradient(
    //       //   linear,
    //       //   40% 0%,
    //       //   75% 84%,
    //       //   from($red-c),
    //       //   to(#707070),
    //       //   color-stop(0.6, #dddddd)
    //       // );
    //       border-radius: 2px;
    //     }
    //   }
    // }
  }
}
