#products-tabs,
#product-description-tabs {
  display: flex;
  justify-content: center;
  row-gap: 10px;
  flex-wrap: wrap;
  // margin-top: 20px;
  // margin-bottom: 40px;
  text-align: center;
  li {
    display: inline-block;
  }
  a {
    display: block;
    padding: 8px 15px;
    border: 1px solid $red-c;
    background-color: $white-c;
    font-weight: 500;
    min-width: 100px;
    margin: 0 5px;
    color: $red-c;
    line-height: 22px;
    font-size: 18px;
  }
  .active a {
    background-color: $color1-c;
    border-color: $color1-c;
    color: $white-c;
  }

  .wrapper-view_all {
    display: flex;
    align-items: center;
    gap: 10px;
    a {
      color: $black-c;
      border: 1px solid $black-c;
      display: flex;
      gap: 5px;
    }
  }
}
.tabs-btns{
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
  .last-btn{
    color: #474747;
    border: 1px solid #474747;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    background-color: #fff;
    font-weight: 500;
    min-width: 100px;
    margin: 0 5px;
    line-height: 22px;
    font-size: 18px;

  }
}
#product-description-tabs {
  margin-bottom: 40px;
}

.product-description-tabs-section {
  background-color: $gray-c;
  padding: 40px 0 40px 0;
  // margin-top: 40px;
 
  .tab-content {
    padding-bottom: 40px;
    & table {
      border: 1px solid #707070 !important;

      tbody {
        tr {
          td {
            padding: 10px !important;
            // width: 100%;
            font-weight: 400;
            color: $black-c !important;
            line-height: 20px;
            font-size: 16px !important;
            &:last-child {
              text-align: left !important;
            }
            p {
              color: $black-c !important;
              span {
                color: $black-c !important;
              }
            }
            span {
              color: $black-c !important;
            }
          }
        }
        tr:nth-child(odd) {
          background-color: white !important;
        }

        tr:nth-child(even) {
          background-color: transparent !important;
        }
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  #products-tabs {
    a {
      min-width: 150px;
    }
  }
}
